import React from 'react'
import './PaddingBox.scss'

const PaddingBox = ({ children }) => (
  <div className="paddingBox__container">{children}</div>
)

PaddingBox.propTypes = {}

export default PaddingBox
