import React, { useState } from 'react'
import { func } from 'prop-types'
import { useMutation } from 'react-apollo'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Spacer from '@components/Spacer/Spacer'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { withSnackbar } from 'notistack'
import stringifyErrors from '@helpers/stringifyErrors'
import { withApollo } from 'react-apollo'
import { GET_USERS } from '@queries'
import { REGISTER_MUTATION } from '@queries/mutations'

import './AdminAddUser.scss'

const AdminAddUser = ({ enqueueSnackbar, client }) => {
  const [newAccount, setNewAccount] = useState({
    email: '',
    admin: false,
  })
  const [registerMutation] = useMutation(REGISTER_MUTATION)

  const registerAction = async e => {
    e.preventDefault()

    try {
      const res = await registerMutation({ variables: newAccount })
      // read cache and upate cache with new data
      const userData = client.readQuery({ query: GET_USERS })
      client.writeQuery({
        query: GET_USERS,
        data: {
          getUsers: [...userData.getUsers, res.data.register],
        },
      })
      enqueueSnackbar('New User Added', {
        variant: 'success',
      })
      // clear add new form
      setNewAccount({
        email: '',
        admin: false,
      })
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  const updateState = (target, value) =>
    setNewAccount({ ...newAccount, [target]: value })

  return (
    <div>
      <form onSubmit={registerAction}>
        <Grid>
          <Grid item>
            <TextField
              label="Email"
              value={newAccount.email}
              onChange={e => updateState('email', e.target.value)}
              margin="normal"
              type="email"
              required
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              label="Password"
              value={newAccount.password}
              onChange={e => updateState('password', e.target.value)}
              margin="normal"
              type="password"
              required
              fullWidth
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newAccount.admin}
                  onChange={e => updateState('admin', !newAccount.admin)}
                  color="primary"
                />
              }
              label="Admin"
            />
          </Grid>
        </Grid>

        <Spacer />
        <Button variant="contained" color="primary" type="submit">
          Register
        </Button>
      </form>
    </div>
  )
}

AdminAddUser.propTypes = { enqueueSnackbar: func }

export default withSnackbar(withApollo(AdminAddUser))
