import React from 'react'
import { string } from 'prop-types'
import './Report.scss'

const Report = ({ example }) => (
  <div>
    <h1>Report</h1>
  </div>
)

Report.propTypes = {
  example: string,
}

export default Report
