import React from 'react'
import { string, number, bool } from 'prop-types'
import cx from 'classnames'
import './Icon.scss'

// generic icon, uses css font
const Icon = ({ icon, size = 24, noPad }) => (
  <span
    className={cx('icon__container', { 'icon__container--no-pad': noPad })}
    style={{ fontSize: Number(size) }}
  >
    <span className={`icons8-${icon}`} />
  </span>
)

Icon.propTypes = {
  icon: string,
  size: number,
  noPad: bool,
}

export default Icon
