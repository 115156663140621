import React from 'react'
import { string } from 'prop-types'
import { CompactPicker } from 'react-color'
import ColorPalette from '@helpers/colorPalette'

import './ColorPicker.scss'

const ColorPicker = ({ setColor, color }) => (
  <div className="ColorPicker__container">
    <CompactPicker
      onChange={({ rgb }) => setColor(rgb)}
      color={color}
      disableAlpha={true}
      colors={ColorPalette}
    />
  </div>
)

ColorPicker.propTypes = {
  example: string,
}

export default ColorPicker
