import React from 'react'
import cx from 'classnames'

import './Spacer.scss'

const Spacer = ({ small }) => (
  <div
    className={cx('spacer__container', { 'spacer__container--small': small })}
  />
)

export default Spacer
