import React, { useState } from 'react'
import { string } from 'prop-types'
import stringifyErrors from '@helpers/stringifyErrors'
import BuildingList from '@components/BuildingList/BuildingList'
import { useMutation } from 'react-apollo'
import { withSnackbar } from 'notistack'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Spacer from '@components/Spacer/Spacer'
import DeleteProjectModal from '@modals/DeleteProjectModal/DeleteProjectModal'
import Dialog from '@material-ui/core/Dialog'
import ImageUploader from '@components/ImageUploader/ImageUploader'
import { PROJECT_MUTATION } from '@queries/mutations'

import './EditProjectDetails.scss'

const EditProjectDetails = ({
  enqueueSnackbar,
  active,
  setActive,
  closeDraw,
}) => {
  const [editProjectMutation] = useMutation(PROJECT_MUTATION)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [picture, setPicture] = useState()

  const { _id: activeId, name, description, address } = active

  const updateState = (target, val) => setActive({ ...active, [target]: val })

  const updateProject = async e => {
    try {
      e.preventDefault()
      const variables = {
        name,
        _id: activeId,
        description,
        address,
      }
      if (picture && picture.length) {
        variables.imageUpload = picture[0]
      }

      const res = await editProjectMutation({
        variables,
      })

      setActive(res.data.updateProject)

      enqueueSnackbar('Project Updated', {
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  return (
    <div className="projectDraw__nav--scroll" style={{ minWidth: '500px' }}>
      <h1 className="projectDraw__header">
        Settings{' '}
        <Button color="secondary" onClick={() => setDeleteModalOpen(true)}>
          Delete Project
        </Button>
      </h1>
      <div className="">
        <form onSubmit={updateProject}>
          <TextField
            label="Name"
            value={name}
            onChange={e => updateState('name', e.target.value)}
            margin="normal"
            type="text"
            required
          />

          <TextField
            label="Description"
            value={description}
            onChange={e => updateState('description', e.target.value)}
            margin="normal"
            type="text"
          />

          <Spacer />

          <p>Project Cover Image</p>

          <ImageUploader callback={e => setPicture(e)} />

          <Button variant="contained" color="primary" type="submit">
            Update Project
          </Button>
        </form>
      </div>
      <Spacer />
      <Spacer />

      <BuildingList projectId={activeId} />

      <Dialog
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(!deleteModalOpen)
        }}
        maxWidth="sm"
        fullWidth
      >
        <DeleteProjectModal
          closeModal={() => {
            setDeleteModalOpen(false)
            closeDraw()
          }}
          project={{ _id: activeId, name }}
        />
      </Dialog>
    </div>
  )
}

EditProjectDetails.propTypes = {
  example: string,
}

export default withSnackbar(EditProjectDetails)
