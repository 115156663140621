import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Button from '@material-ui/core/Button'
import { useMutation } from 'react-apollo'
import { withApollo } from 'react-apollo'
import { withSnackbar } from 'notistack'
import stringifyErrors from '@helpers/stringifyErrors'
import getId from '@helpers/getId'
import { GET_USERS } from '@queries'
import { DELETE_USER_MUTATION } from '@queries/mutations'

import './AdminUserList.scss'

const AdminUserList = ({ client, enqueueSnackbar }) => {
  const userId = getId()
  const [sortedData, setSortedData] = useState([])
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('asc')
  const [deleteMutation] = useMutation(DELETE_USER_MUTATION)

  const { loading, error, data = {} } = useQuery(GET_USERS)

  const deleteUser = async _id => {
    try {
      const res = await deleteMutation({ variables: { _id } })

      client.writeQuery({
        query: GET_USERS,
        data: {
          getUsers: res.data.deleteUser,
        },
      })
      enqueueSnackbar('User Deleted', {
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    setSortedData(data.getUsers || [])
  }, [data.getUsers])

  const sortData = target => {
    const isDesc = orderBy === target && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(target)
    const sortIt = sortedData.sort((a, b) =>
      String(a[target]).localeCompare(String(b[target])),
    )
    const checkOrder = isDesc ? sortIt.reverse() : sortIt
    setSortedData(checkOrder)
  }

  if (loading) return 'Loading...'
  if (error) return `Error! ${error.message}`

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={orderBy === 'email'}
              direction={order}
              onClick={() => sortData('email')}
            >
              Email
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === 'givenname'}
              direction={order}
              onClick={() => sortData('givenname')}
            >
              Firstname
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === 'surname'}
              direction={order}
              onClick={() => sortData('surname')}
            >
              Lastname
            </TableSortLabel>
          </TableCell>
          <TableCell align="right">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map(({ givenname, surname, email, _id }) => (
          <TableRow key={_id}>
            <TableCell>{email}</TableCell>
            <TableCell>{givenname}</TableCell>
            <TableCell>{surname}</TableCell>
            <TableCell align="right">
              {userId !== _id && (
                <Button color="secondary" onClick={() => deleteUser(_id)}>
                  Delete Log
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

AdminUserList.propTypes = {}

export default withSnackbar(withApollo(AdminUserList))
