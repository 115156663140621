export const pluginList = [
  'NavigationToolbar',
  'VerticleExpload',
  'AddLabels',
  'ToggleColors',
  'VisualizationToolbar',
  'RoomProperties',
  'ReportCapture',
]

const initPlugins = async viewer => {
  // build a list of all plugins based on PlugList var
  const allPluginsImports = pluginList.map(item => import(`./${item}.js`))
  // load them all
  const resolveAllPlugins = await Promise.all(allPluginsImports)

  // loading this way because the plugin.name was being uglfied and mis-matching
  for (const [index, name] of pluginList.entries()) {
    const Plugin = resolveAllPlugins[index].default
    // register the extensions
    await Autodesk.Viewing.theExtensionManager.registerExtension(name, Plugin)
    // then init plugin
    new Plugin(viewer)
  }

  return true
}

export default initPlugins
