import React, { useState } from 'react'
import { string } from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { useMutation } from 'react-apollo'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import { GET_COLOR_BY_PROJECT } from '@queries'
import forge from '@forge'
import { COLOR_MUTATION } from '@queries/mutations'
import ColorPicker from '@components/ColorPicker/ColorPicker'

import './EditColorModal.scss'

const EditColorModal = ({
  data,
  projectCode,
  enqueueSnackbar,
  client,
  closeModal,
}) => {
  const { name, target, _id, r, g, b } = data

  const [color, setColor] = useState({ r, g, b })

  const [colorMutation] = useMutation(COLOR_MUTATION)

  const saveColor = async e => {
    e.preventDefault()

    const saveData = {
      _id,
      name,
      type: target,
      project_id: projectCode,
      ...color,
    }

    try {
      const { data = {} } = await colorMutation({ variables: saveData })

      client.writeQuery({
        query: GET_COLOR_BY_PROJECT,
        data: {
          getColorByProject: data.editColor,
        },
        variables: saveData,
      })

      enqueueSnackbar('Color Changed', {
        variant: 'success',
      })
      forge.updateColors()
      closeModal()
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <DialogTitle>Select Color </DialogTitle>

      <DialogContent>
        <form onSubmit={saveColor}>
          <div className="editColor__boxWrapper">
            <ColorPicker setColor={setColor} color={color} />
          </div>
          <Button variant="contained" color="primary" type="submit">
            Save Color
          </Button>
        </form>
      </DialogContent>
    </div>
  )
}

EditColorModal.propTypes = {
  code: string,
}

export default withSnackbar(withApollo(EditColorModal))
