const colorPalette = [
  '#262626',
  '#E9E9E9',
  '#333C69',
  '#B4DCFA',
  '#4E67C8',
  '#5ECCF3',
  '#A7EA52',
  '#5DCEAF',
  '#FFC000',
  '#FF8021',
  '#F14124',
  '#59A8D1',
  '#954F72',
  '#7F7F7F',
  '#F2F2F2',
  '#C7CCE4',
  '#8BC9F7',
  '#DBE0F3',
  '#DEF4FC',
  '#EDFADC',
  '#DEF5EF',
  '#FFF2CC',
  '#FFE5D2',
  '#FCD9D3',
  '#DDEDF5',
  '#EBDAE2',
  '#595959',
  '#D8D8D8',
  '#909ACA',
  '#4FACF3',
  '#B8C2E9',
  '#BEEAFA',
  '#DBF6B9',
  '#BEEBDF',
  '#FEE599',
  '#FECCA6',
  '#F9B3A7',
  '#BCDCEC',
  '#D7B5C6',
  '#3F3F3F',
  '#BFBFBF',
  '#5967AF',
  '#0D78C9',
  '#94A3DD',
  '#9EE0F7',
  '#CAF297',
  '#9DE1CE',
  '#FFD965',
  '#FFB279',
  '#F68D7B',
  '#9BCAE3',
  '#C490AA',
  '#262626',
  '#A5A5A5',
  '#181D33',
  '#063C64',
  '#31479F',
  '#11B2EB',
  '#81D319',
  '#34AC8B',
  '#BF9000',
  '#D85C00',
  '#C3260C',
  '#3083AF',
  '#6F3B55',
  '#0C0C0C',
  '#7F7F7F',
  '#101322',
  '#021828',
  '#202F6A',
  '#0B769C',
  '#568C11',
  '#22725C',
  '#7F6000',
  '#903D00',
  '#821908',
  '#205774',
  '#4A2739',
]

export default colorPalette
