import gql from 'graphql-tag'

export const GET_PROJECTS = gql`
  {
    getProjects {
      _id
      name
      description
      picture
    }
  }
`

export const GET_SCENARIOS = gql`
  query GetScenarioByProject($project_id: Int!) {
    getScenarioByProject(project_id: $project_id) {
      _id
      project_id
      name
      scenario_dates {
        _id
        name
        scenario_id
        date
        comments
        r
        g
        b
      }
    }
  }
`

export const GET_BUILDINGS = gql`
  query GetBuildingsByProject($project_id: String!) {
    getBuildingsByProject(project_id: $project_id) {
      _id
      urn_id
      name
      description
      address
      picture
      urn
      cord_x
      cord_y
      cord_z
      rotation
      updated_at
    }
  }
`

export const GET_ROOMS_BY_ID = gql`
  query GetRoomsByProject($_id: String!) {
    getRoomsByProject(_id: $_id) {
      _id
      room_code
      area
      dp
      dv
      rmtype
      rmpfun
      room_uuid
      rmfun
      rmfuntype
    }
  }
`

export const GET_ROOMS_BY_SELECTED = gql`
  query GetRoomsBySelected(
    $project_id: String!
    $child: String!
    $building_filter: String
    $level_filter: String
    $faculty_filter: String
    $department_filter: String
    $type_filter: String
    $function_filter: String
    $activePastScenario: String
    $activeFutureScenario: String
  ) {
    getRoomsBySelected(
      project_id: $project_id
      child: $child
      building_filter: $building_filter
      level_filter: $level_filter
      faculty_filter: $faculty_filter
      department_filter: $department_filter
      type_filter: $type_filter
      function_filter: $function_filter
      activePastScenario: $activePastScenario
      activeFutureScenario: $activeFutureScenario
    ) {
      previous {
        _id
        room_uuid
        area
        fl_id
        bl_id
        area
        dp_id
        dv_id
        rm_type
        rm_cat
        pr_rm_fu
        room_uuid
        rm_fu_code
        rm_fu_type
      }
      current {
        _id
        room_uuid
        area
        fl_id
        bl_id
        area
        dp_id
        dv_id
        rm_type
        rm_cat
        pr_rm_fu
        room_uuid
        rm_fu_code
        rm_fu_type
      }
      next {
        _id
        room_uuid
        area
        fl_id
        bl_id
        area
        dp_id
        dv_id
        rm_type
        rm_cat
        pr_rm_fu
        room_uuid
        rm_fu_code
        rm_fu_type
      }
    }
  }
`

export const GET_ROOMS_BY_EXTRA_DATA = gql`
  query GetRoomsByExtraData($_id: String!) {
    getRoomsByExtraData(_id: $_id) {
      _id
      room_uuid
      area
      fl_id
      bl_id
      area
      dp_id
      dv_id
      rm_type
      rm_cat
      pr_rm_fu
      room_uuid
      rm_fu_code
      rm_fu_type
    }
  }
`

export const GET_ROOMS_BY_UUID = gql`
  query GetRoomsByList($projectId: String!, $roomList: [String]!) {
    getRoomsByList(projectId: $projectId, roomList: $roomList) {
      _id
      room_uuid
      area
      fl_id
      bl_id
      area
      dp_id
      dv_id
      rm_type
      rm_cat
      pr_rm_fu
      room_uuid
      rm_fu_code
      rm_fu_type
    }
  }
`

export const POST_SCENARIO_ROOM_MUTATION = gql`
  mutation EditScenarioRoom(
    $scenario_date_id: String!
    $room_code: [String]!
    $room_data: String!
  ) {
    editScenarioRoom(
      scenario_date_id: $scenario_date_id
      room_code: $room_code
      room_data: $room_data
    ) {
      _id
      scenario_date_id
      room_data
      room_code
    }
  }
`

export const GET_ROOM_SCENARIO_DATA = gql`
  query GetRoomScenarioByProject($_id: String!) {
    getRoomScenarioByProject(_id: $_id) {
      _id
      scenario_date_id
      room_data
      room_code
    }
  }
`
export const GET_COLOR_BY_PROJECT = gql`
  query GetColorByProject($project_id: String!) {
    getColorByProject(project_id: $project_id) {
      _id
      name
      r
      g
      b
      project_id
      type
    }
  }
`

export const MOVE_SCENARIO_ROOM_MUTATION = gql`
  mutation MoveScenarioRoom(
    $scenario_date_id: String!
    $old_room_id: String!
    $new_room_id: [String]!
    $old_room_data: String!
    $new_room_data: String!
  ) {
    moveScenarioRoom(
      scenario_date_id: $scenario_date_id
      old_room_id: $old_room_id
      new_room_id: $new_room_id
      old_room_data: $old_room_data
      new_room_data: $new_room_data
    ) {
      _id
      scenario_date_id
      room_data
      room_code
    }
  }
`

export const SWAP_SCENARIO_ROOM_MUTATION = gql`
  mutation SwapScenarioRoom(
    $scenario_date_id: String!
    $old_room_id: String!
    $new_room_id: String!
    $old_room_data: String!
    $new_room_data: String!
  ) {
    swapScenarioRoom(
      scenario_date_id: $scenario_date_id
      old_room_id: $old_room_id
      new_room_id: $new_room_id
      old_room_data: $old_room_data
      new_room_data: $new_room_data
    ) {
      _id
      scenario_date_id
      room_data
      room_code
    }
  }
`

export const RESET_SCENARIO_ROOM_MUTATION = gql`
  mutation ResetScenarioRoom($scenario_date_id: String!, $room_ids: [String]!) {
    resetScenarioRoom(
      scenario_date_id: $scenario_date_id
      room_ids: $room_ids
    ) {
      _id
      scenario_date_id
      room_data
      room_code
    }
  }
`

export const CLEAR_SCENARIO_ROOM_MUTATION = gql`
  mutation ClearScenarioRoom(
    $scenario_date_id: String!
    $room_ids: [String]!
    $room_data: String!
  ) {
    clearScenarioRoom(
      scenario_date_id: $scenario_date_id
      room_ids: $room_ids
      room_data: $room_data
    ) {
      _id
      scenario_date_id
      room_data
      room_code
    }
  }
`

export const GET_FILTER_DATA = gql`
  query GetFilterData($project_id: String!) {
    getFilterData(project_id: $project_id) {
      buildings
      levels
      faculties
      departments
      types
      functions
    }
  }
`

export const GET_ROOM_LABELS = gql`
  query GetReferences {
    getReferences {
      rmcat {
        _id
        rm_cat
        description
      }
      rmtype {
        _id
        rm_cat
        rm_type
        description
      }
      dv {
        _id
        dv_id
        name
        bu_id
      }
      dp {
        _id
        dv_id
        dp_id
        name
      }
      rmpfun {
        _id
        pr_rm_fu
        pr_rm_desc
      }
      rmfun {
        _id
        pr_rm_fu
        rm_fu_code
        rm_fu_desc
      }
      rmfuntype {
        _id
        pr_rm_fu
        rm_fu_code
        rm_fu_type
        rm_fu_type_desc
      }
    }
  }
`

export const GET_DRAWING_DATA = gql`
  query GetAllDrawings {
    getAllDrawings {
      building
      modified
      urn
      drawings {
        Key
        LastModified
      }
    }
  }
`

export const GET_REPORT = gql`
  query GetReport {
    getReport {
      isRunning
      reports {
        message
        isSuccess
        date
      }
    }
  }
`

export const GET_URNS = gql`
  query GetUrns {
    getUrns {
      _id
      urn
      building_code
    }
  }
`

export const GET_CSV_DATA = gql`
  query GetCsvData {
    getCsvData {
      name
      modified
      url
    }
  }
`

export const GET_CSV_REPORT = gql`
  query GetCsvReport {
    getCsvReport {
      message
      isSuccess
      date
    }
  }
`

export const GET_USERS = gql`
  {
    getUsers {
      _id
      email
      admin
      givenname
      surname
      created_at
    }
  }
`

export const GET_TOKEN = gql`
  query GetToken {
    getToken {
      token
      expires_in
    }
  }
`

export const GET_USER = gql`
  query GetUser($_id: String!) {
    getUser(_id: $_id) {
      _id
      email
      givenname
      surname
    }
  }
`

export const GET_REPORTS = gql`
  query GetAllReports($project_id: Int!) {
    getAllReports(project_id: $project_id) {
      _id
      name
      image
      project_id
      activeScenario
      activeScenarioPrev
      activeScenarioNext
      building_filter
      level_filter
      faculty_filter
      department_filter
      type_filter
      function_filter
      openFilterTab
      activeScenarioParent
      scenario_name
      event_name
      created_at
    }
  }
`
export const GET_MODEL_SIGNED_URL = gql`
  query GetModelSignedUrl($building: String!) {
    getModelSignedUrl(building: $building) {
      signedUrl
    }
  }
`
