import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-client'
import { createBrowserHistory } from 'history'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'

import Container from '@components/Container/Container'
import NotFound from '@components/NotFound/NotFound'
import Login from '@pages/Login/Login'
import Viewer from '@pages/Viewer/Viewer'
import Report from '@pages/Report/Report'
import AdminUsers from '@pages/AdminUsers/AdminUsers'
import AdminBuildings from '@pages/AdminBuildings/AdminBuildings'
import AdminCsv from '@pages/AdminCsv/AdminCsv'
import User from '@pages/User/User'
import configureStore from '@redux/store'
import theme from '@helpers/theme'

import store from './store'
import connection from './connection'

import '@styles/index.scss'

export const history = createBrowserHistory()

export const reduxStore = configureStore()

export const client = new ApolloClient({
  link: connection,
  cache: store,
  assumeImmutableResults: true,
  resolvers: {},
})

const AppRouter = () => (
  <Provider store={reduxStore}>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={4}
        autoHideDuration={3500}
        disableWindowBlurListener={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ApolloProvider client={client}>
          <Router history={history}>
            <Switch>
              <Route path="/login" component={Login} />
              <Container>
                <Switch>
                  <Route path="/viewer" component={Viewer} />
                  <Route path="/viewer/:projectId" component={Viewer} />
                  <Route path="/report" component={Report} />
                  <Route path="/admin-users" component={AdminUsers} />
                  <Route path="/admin-buildings" component={AdminBuildings} />
                  <Route path="/admin-csv" component={AdminCsv} />
                  <Route path="/user" component={User} />
                  <Route exact path="/" component={Viewer} />

                  <Route exact component={NotFound} />
                </Switch>
              </Container>
            </Switch>
          </Router>
        </ApolloProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  </Provider>
)

export default AppRouter
