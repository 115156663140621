import React, { useState, Fragment } from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Spacer from '@components/Spacer/Spacer'
import { useDropzone } from 'react-dropzone'
import { useMutation } from 'react-apollo'
import { UPLOAD_FILES } from '@queries/mutations'
import Icon from '@components/Icon/Icon'
import './UploadModal.scss'

//Test
const UploadModal = ({ closeModal, fileType }) => {
  const [completed, setCompleted] = useState(0)

  const [uploadFile] = useMutation(UPLOAD_FILES)

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: `.${fileType}`,
  })

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      <p style={{ display: 'flex', alignItems: 'center', padding: '2px 10px' }}>
        <Icon icon={'file'} /> {file.path} -{' '}
        {Math.ceil(file.size / 1024).toLocaleString()}KB
      </p>
    </li>
  ))

  const onUploadFiles = () => {
    if (acceptedFiles.length > 0) {
      const percentage = 100 / acceptedFiles.length
      let localCount = 0

      acceptedFiles.forEach(async file => {
        const type = fileType
        const variables = { file, type }
        await uploadFile({ variables })
        localCount = localCount + percentage
        setCompleted(localCount)
      })
    }
  }

  const dropzone = () => {
    return (
      <Fragment>
        <DialogContent dividers>
          <div {...getRootProps({ className: 'uploadModal__dropzone' })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
          </div>

          <Spacer />
          <ul>{files}</ul>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ width: '100%' }}
            variant="contained"
            color="primary"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            style={{ width: '100%' }}
            variant="contained"
            color="primary"
            disabled={acceptedFiles.length > 0 ? false : true}
            onClick={() => onUploadFiles()}
          >
            Upload
          </Button>
        </DialogActions>
      </Fragment>
    )
  }

  const status = () => {
    return (
      <Fragment>
        <DialogContent dividers>
          <Spacer />
          <LinearProgress variant="determinate" value={completed} />
          <Spacer />
        </DialogContent>
        <DialogActions>
          <Button
            style={{ width: '100%' }}
            variant="contained"
            color="primary"
            disabled={completed > 99 ? false : true}
            onClick={() => closeModal()}
          >
            Close
          </Button>
        </DialogActions>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <DialogTitle>
        {completed > 0
          ? completed > 99
            ? 'Upload Complete'
            : 'Uploading Files'
          : 'Select Files'}
      </DialogTitle>
      {completed > 0 ? status() : dropzone()}
    </Fragment>
  )
}

UploadModal.propTypes = {}

export default UploadModal
