import decode from 'jwt-decode'

const getId = () => {
  const token = localStorage.getItem('token')
  if (!token) {
    return false
  }
  const { _id } = decode(token)
  return _id || null
}

export default getId
