import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_ROOMS_BY_SELECTED, GET_COLOR_BY_PROJECT } from '@queries'
import { connect } from 'react-redux'
import { GET_SCENARIOS } from '@queries'
import RoomInfoTabsChild from '@components/RoomInfoTabsChild/RoomInfoTabsChild'
import config from '@config'
import Spacer from '@components/Spacer/Spacer'
import format from 'date-fns/format'

import './ReportDrawPreview.scss'

const titles = ['Faculty', 'Department', 'Type', 'Function']

const ReportDrawPreview = ({ filters, labels, menus, item }) => {
  const {
    name,
    image,
    project_id,
    activeScenario,
    activeScenarioPrev,
    activeScenarioNext,
    building_filter,
    level_filter,
    faculty_filter,
    department_filter,
    type_filter,
    function_filter,
    openFilterTab,
    activeScenarioParent,

    event_name,
    created_at,
  } = item

  const active = Number(openFilterTab)

  const { data: allData = {} } = useQuery(GET_ROOMS_BY_SELECTED, {
    variables: {
      project_id,
      child: activeScenario || '',
      activePastScenario: activeScenarioPrev || '',
      activeFutureScenario: activeScenarioNext || '',
      building_filter,
      level_filter,
      faculty_filter,
      department_filter,
      type_filter,
      function_filter,
    },
    fetchPolicy: 'no-cache',
  })

  const dataForChild = allData.getRoomsBySelected || {
    previous: [],
    current: [],
    next: [],
  }

  const { data: colorData = {} } = useQuery(GET_COLOR_BY_PROJECT, {
    variables: {
      project_id,
    },
  })

  const { data: scenarioData = {} } = useQuery(GET_SCENARIOS, {
    variables: { project_id: Number(project_id) },
  })

  const senarioList = scenarioData.getScenarioByProject || []
  const activeScenarioList = senarioList.find(
    item => item._id === activeScenarioParent,
  ) || { scenario_dates: [] }
  const sortedByDateList = [...activeScenarioList.scenario_dates].sort(
    (a, b) => a.date - b.date,
  )

  const colorCodes = colorData.getColorByProject || []

  return (
    <div className="reportDrawPreview__container">
      {image && (
        <img src={`${config.imageServer}/${encodeURI(image)}`} alt="" />
      )}

      <Spacer small />

      <h2>
        {`${name} - ${format(new Date(Number(created_at)), 'dd MMM yyyy')}`}
      </h2>

      <p>Event: {event_name || 'None'}</p>

      <p>Data type: {titles[Number(openFilterTab) - 1]}</p>

      <h2>Filters</h2>

      <div className="reportDrawPreview__filtersContainer">
        <div>
          <p>Building: {building_filter}</p>
          <p>Level: {level_filter}</p>
        </div>

        <div>
          <p>Facuilty: {faculty_filter}</p>
          <p>Department: {department_filter}</p>
        </div>

        <div>
          <p>Type: {type_filter}</p>
          <p>Function: {function_filter}</p>
        </div>
      </div>

      <Spacer small />

      <div className="popMenu__cell">
        <div className="">
          <div className="popMenu__menu--clip">
            <div className="">
              {active === 1 && (
                <RoomInfoTabsChild
                  data={dataForChild}
                  target="dv_id"
                  labels={labels.dv}
                  senarioList={sortedByDateList}
                  colorCodes={colorCodes}
                  editDisabled={true}
                />
              )}

              {active === 2 && (
                <RoomInfoTabsChild
                  data={dataForChild}
                  target="dp_id"
                  labels={labels.dp}
                  senarioList={sortedByDateList}
                  colorCodes={colorCodes}
                  editDisabled={true}
                />
              )}

              {active === 3 && (
                <RoomInfoTabsChild
                  data={dataForChild}
                  target="rm_type"
                  labels={labels.rmtype}
                  senarioList={sortedByDateList}
                  colorCodes={colorCodes}
                  editDisabled={true}
                />
              )}

              {active === 4 && (
                <RoomInfoTabsChild
                  data={dataForChild}
                  target="pr_rm_fu"
                  labels={labels.rmpfun}
                  senarioList={sortedByDateList}
                  colorCodes={colorCodes}
                  editDisabled={true}
                />
              )}

              {active === 5 && (
                <table className="roomData__table roomData__table--single">
                  <thead>
                    <tr>
                      <th>Room Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataForChild.current.map(
                      ({ room_code, room_uuid, area }) => {
                        return (
                          <tr key={room_uuid}>
                            <td>
                              {room_uuid} - {Number(area).toLocaleString()}m²
                            </td>
                          </tr>
                        )
                      },
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ReportDrawPreview.propTypes = {}

const mapStateToProps = ({ labels, filters, menus }) => ({
  labels,
  filters,
  menus,
})

const mapDispatchToProps = d => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportDrawPreview)
