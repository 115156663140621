import React, { Fragment } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { GET_SCENARIOS } from '@queries'
import { useQuery } from '@apollo/react-hooks'
import format from 'date-fns/format'
import cx from 'classnames'
import { withApollo } from 'react-apollo'
import forge from '@forge'
import { connect } from 'react-redux'
import { updateMenuState } from '@redux/actions'
import parseISO from 'date-fns/parseISO'
import groupBy from 'lodash.groupby'
import Icon from '@components/Icon/Icon'

import './Scenario.scss'

const Scenario = ({ client, menus, update }) => {
  const { activeScenario, activeScenarioParent, openProject: projectId } = menus
  const { data: scenarioData = {} } = useQuery(GET_SCENARIOS, {
    variables: { project_id: Number(projectId) },
  })
  const finalList = scenarioData.getScenarioByProject || []
  const getCorrectData = finalList.find(
    item => item._id === activeScenarioParent,
  )
  const parseChildren = getCorrectData ? getCorrectData.scenario_dates : []
  const sortedByDateList = [...parseChildren].sort((a, b) => a.date - b.date)
  const groupByDate = groupBy(sortedByDateList, item =>
    format(parseISO(item.date), 'yyyy-MM-dd'),
  )

  return (
    <div className="scenario__container">
      {projectId && (
        <Fragment>
          <div className="scenario__containerSelect">
            <FormControl fullWidth>
              <InputLabel id="scenario-label">Scenario</InputLabel>
              <Select
                labelId="scenario-label"
                onChange={e => {
                  const target = e.target.value
                  update({
                    activeScenarioParent: target,
                  })
                }}
                autoWidth
                value={activeScenarioParent || ''}
              >
                <MenuItem value={false}>None</MenuItem>
                {finalList.map(item => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {activeScenarioParent && (
            <div className="scenario__subContainer">
              <div
                className={cx(
                  'scenario__itemTitle',
                  'scenario__itemTitle--first',
                  {
                    'scenario__itemTitle--active': !activeScenario,
                  },
                )}
                onClick={() => {
                  update({
                    activeScenario: null,
                  })
                  forge.updateScenarioApi()
                }}
              >
                Initial
              </div>

              {Object.entries(groupByDate).map(([key, value]) => (
                <div className={'scenario__item'} key={key}>
                  <div
                    className={cx('scenario__itemTitle', {
                      'scenario__itemTitle--active': value.find(
                        item => item._id === activeScenario,
                      ),
                    })}
                  >
                    {format(new Date(key), 'dd MMM yyyy')}
                  </div>
                  {value.map(item => (
                    <div
                      className={cx('scenario__itemChild', {
                        'scenario__item--active': activeScenario === item._id,
                      })}
                      onClick={async () => {
                        update({
                          activeScenario: item._id,
                        })
                        // this updates the forge extention with correct scenario ID's
                        forge.updateScenarioApi()
                      }}
                      key={item._id}
                    >
                      {item.name}
                      <span
                        className="scenario__itemChildIcon"
                        style={{
                          color: `rgb(${item.r},${item.g},${item.b})`,
                        }}
                      >
                        <Icon icon={'tag-window-2'} size={24} />
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

Scenario.propTypes = {}

const mapStateToProps = ({ menus }) => ({ menus })

const mapDispatchToProps = dispatch => ({
  update: data => dispatch(updateMenuState(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApollo(Scenario))
