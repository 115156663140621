import React, { useEffect, useState } from 'react'
import { bool } from 'prop-types'

import './Fader.scss'

const Fader = ({ show, children }) => {
  const [render, setRender] = useState(show)

  useEffect(() => {
    if (show) setRender(true)
  }, [show])

  const onAnimationEnd = () => {
    if (!show) setRender(false)
  }

  return render ? (
    <div
      style={{ animation: `${show ? 'fadeIn' : 'fadeOut'} 0.3s ease-in-out` }}
      onAnimationEnd={onAnimationEnd}
      className="fader__container"
    >
      {children}
    </div>
  ) : (
    ''
  )
}

Fader.propTypes = {
  show: bool,
}

export default Fader
