import React, { useState, Fragment, useEffect } from 'react'
import { string } from 'prop-types'
import TextField from '@material-ui/core/TextField'
import forge from '@forge'
import { connect } from 'react-redux'
import SearchBoxResults from '@components/SearchBoxResults/SearchBoxResults'

import './SearchBox.scss'

const SearchBox = ({ nodes, setUpdateChildrenId }) => {
  const [lookUpDate, setLookUpData] = useState({})
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [results, setResults] = useState([])
  const { buildingNodes } = nodes

  const changeResults = e => {
    e.preventDefault()
    const value = e.target.value || ''
    const filtered = data.filter(item =>
      item.toLowerCase().includes(value.toLowerCase()),
    )
    setResults(filtered)
  }

  const clearSearch = () => {
    setResults(data)
    setSearchTerm('')
    setUpdateChildrenId([])
    forge.showAllModels()
  }

  const clearSearchResult = () => {
    setResults(data)
    setSearchTerm('')
  }

  useEffect(() => {
    const nodes = buildingNodes.flat().filter(item => item.metaData)
    const searchStrings = [
      ...new Set(
        nodes.map(
          ({ dbId, metaData, modelId }) =>
            `${metaData.bl_id} - ${metaData.rm_id}`,
        ),
      ),
    ]

    const lookUpData = nodes.reduce((acc, { dbId, metaData, modelId }) => {
      acc[`${metaData.bl_id} - ${metaData.rm_id}`] = {
        dbId,
        modelId,
        ...metaData,
      }
      return acc
    }, {})

    setData(searchStrings)
    setLookUpData(lookUpData)
  }, [buildingNodes, buildingNodes.length])

  return (
    <Fragment>
      <h1 className="leftNav__title">
        Project Browser
        <span className="leftNav__title--button" onClick={clearSearch}>
          Clear Selection
        </span>
      </h1>
      <div className="searcbBox__container">
        <TextField
          label="Search Rooms"
          fullWidth
          variant="outlined"
          size="small"
          onChange={e => {
            setSearchTerm(e.target.value)
            changeResults(e)
          }}
          value={searchTerm}
          margin="none"
        />

        <SearchBoxResults
          results={results}
          searchTerm={searchTerm}
          setUpdateChildrenId={setUpdateChildrenId}
          lookUpDate={lookUpDate}
          clearSearch={clearSearchResult}
        />
      </div>
    </Fragment>
  )
}

SearchBox.propTypes = {
  example: string,
}

const mapStateToProps = ({ nodes }) => ({ nodes })

export default connect(
  mapStateToProps,
  null,
)(SearchBox)
