import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from 'react'
import { func, bool } from 'prop-types'
import BackupIcon from '@material-ui/icons/Backup'
import Button from '@material-ui/core/Button'

import './ImageUploader.scss'

const ImageUploader = ({ callback, multi }) => {
  const [id] = useState(Math.floor(Math.random() * 10000000) + 1)
  const [dragging, setDragging] = useState(false)
  // this counter is used to prevent child events on the dragover, doubled up with css pointer-events: none
  const [counter, setCounter] = useState(0)
  const [fileList, setFileList] = useState([])
  const dropZone = useRef()

  const handleDrag = useCallback(e => {
    stopDefaults(e)
  }, [])

  const handleDragIn = useCallback(
    e => {
      stopDefaults(e)
      setCounter(counter + 1)
      const { items } = e.dataTransfer
      if (items && items.length > 0) {
        setDragging(true)
      }
    },
    [counter],
  )

  const handleDragOut = useCallback(
    e => {
      stopDefaults(e)
      setCounter(counter - 1)
      if (counter === 0) {
        setDragging(false)
      }
    },
    [counter],
  )

  const handleDrop = useCallback(
    e => {
      stopDefaults(e)
      setDragging(false)
      const { files } = e.dataTransfer
      if (files && files.length > 0) {
        multi
          ? setFileList(prevList => [...prevList, ...files])
          : setFileList([files[0]])

        callback && callback(files)
        e.dataTransfer.clearData()
        setCounter(0)
      }
    },
    [callback, multi],
  )

  useEffect(() => {
    const target = dropZone.current
    target.addEventListener('dragenter', handleDragIn)
    target.addEventListener('dragleave', handleDragOut)
    target.addEventListener('dragover', handleDrag)
    target.addEventListener('drop', handleDrop)
    return () => {
      target.removeEventListener('dragenter', handleDragIn)
      target.removeEventListener('dragleave', handleDragOut)
      target.removeEventListener('dragover', handleDrag)
      target.removeEventListener('drop', handleDrop)
      setCounter(0)
    }
  }, [handleDragIn, handleDragOut, handleDrag, handleDrop])

  const stopDefaults = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const inputFileSelect = e => {
    stopDefaults(e)
    const { files } = e.target
    if (files && files.length > 0) {
      const fileListParse = multi ? files : [files[0]]
      setFileList(fileListParse)
      callback && callback(fileListParse)
    }
  }

  return (
    <Fragment>
      <div
        className={`imageUploader__container ${
          dragging ? 'imageUploader__container--active' : ''
        }`}
        ref={dropZone}
      >
        <BackupIcon />
        <h2>Drag image to upload</h2>
        <p>or</p>
        <input
          accept="image/*"
          id={id}
          type="file"
          className="imageUploader__hiddenInput"
          onChange={e => inputFileSelect(e)}
        />
        <label htmlFor={id}>
          <Button component="span" color="primary">
            Browse Files
          </Button>
        </label>
      </div>

      {fileList.map(item => (
        <div className="imageUploader__fileList" key={item.lastModified}>
          {item.name}
        </div>
      ))}
    </Fragment>
  )
}

ImageUploader.propTypes = {
  callback: func,
  multi: bool,
}

export default ImageUploader
