import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from 'apollo-link'
import config from '@config'

const httpLink = createUploadLink({
  uri: config.apiServer,
})

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token')

  operation.setContext({
    headers: {
      authorization: token ? token : '',
    },
  })

  return forward(operation)
})

export default authLink.concat(httpLink)
