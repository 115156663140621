import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Spacer from '@components/Spacer/Spacer'
import DialogContent from '@material-ui/core/DialogContent'
import { useMutation } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import { GET_SCENARIOS } from '@queries'
import {
  NEW_SCENARIO_MUTATION,
  DELETE_SCENARIO_MUTATION,
  EDIT_SCENARIO_MUTATION,
} from '@queries/mutations'

import './NewScenario.scss'

const NewScenario = ({
  enqueueSnackbar,
  client,
  closeModal,
  projectId,
  data,
  resetChild,
  callback,
}) => {
  const [formVals, setFormVals] = useState(
    data
      ? data
      : {
          name: '',
        },
  )

  const { name } = formVals
  const [newScenarioMutation] = useMutation(
    data.name ? EDIT_SCENARIO_MUTATION : NEW_SCENARIO_MUTATION,
  )
  const [deleteScenario] = useMutation(DELETE_SCENARIO_MUTATION)

  const updateFormVals = (target, val) =>
    setFormVals({ ...formVals, [target]: val })

  const newScenarioAction = async e => {
    e.preventDefault()

    try {
      const variables = {
        name,
        project_id: Number(projectId),
        _id: Number(data._id),
      }

      await newScenarioMutation({
        variables,
      })

      enqueueSnackbar(data.name ? 'Scenario Edited' : 'Scenario Created', {
        variant: 'success',
      })

      callback()
      closeModal()
    } catch (e) {
      console.log(e)
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  const deleteMe = async e => {
    e.preventDefault()

    const { data: resData } = await deleteScenario({
      variables: { _id: Number(data._id) },
    })

    await client.writeQuery({
      query: GET_SCENARIOS,
      variables: { project_id: Number(projectId) },
      data: {
        getScenarioByProject: resData.deleteScenario,
      },
    })

    enqueueSnackbar('Scenario Deleted', {
      variant: 'success',
    })
    closeModal()
    resetChild()
  }

  return (
    <div>
      <DialogTitle>
        {data ? 'Edit Scenario' : 'Create New Scenario'}
        {data && <Button onClick={deleteMe}>Delete</Button>}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={newScenarioAction}>
          <TextField
            label="Name"
            value={name}
            onChange={e => updateFormVals('name', e.target.value)}
            margin="normal"
            type="text"
            required
          />

          <Spacer />

          <Button variant="contained" color="primary" type="submit">
            {data ? 'Save Scenario' : 'Save New Scenario'}
          </Button>
        </form>
      </DialogContent>
    </div>
  )
}

NewScenario.propTypes = {}

export default withSnackbar(withApollo(NewScenario))
