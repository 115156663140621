import { client } from '@root/Router'
import { GET_ROOM_LABELS } from '@queries'

export const fetchData = () => async dispatch => {
  const {
    data: { getReferences = {} },
  } = await client.query({
    query: GET_ROOM_LABELS,
  })

  dispatch({
    type: 'STORE_LABELS',
    ...getReferences,
  })
}

export const updateMenuState = menuState => async dispatch => {
  dispatch({
    type: 'MENU_STATE',
    menuState,
  })
}

export const updateFiltersState = filters => async dispatch => {
  dispatch({
    type: 'FILTER_STATE',
    filters,
  })
}

export const resetAllFilters = () => async dispatch => {
  dispatch({
    type: 'FILTER_STATE_RESET',
  })
}

export const storeBuildingNodes = nodes => async dispatch => {
  dispatch({
    type: 'STORE_BUILDING_NODES',
    nodes,
  })
}

export const updateUser = token => async dispatch => {
  dispatch({
    type: 'UPDATE_USER',
    token,
  })
}

export const resetAllState = () => async dispatch => {
  dispatch({
    type: 'RESET',
  })
}

export const captureUpdate = ({ image }) => async dispatch => {
  dispatch({
    type: 'CAPTURE_UPDATE',
    image,
    reportNameModalOpen: true,
  })
}

export const getReportData = () => async (dispatch, getStore) => {
  const { filters, menus, report } = getStore()
  const {
    openProject,
    activeScenario,
    activeScenarioPrev,
    activeScenarioNext,
  } = menus
  const {
    bl_id_filter,
    fl_id_filter,
    dv_id_filter,
    dp_id_filter,
    rm_type_filter,
    pr_rm_fu_filter,
    openFilterTab,
  } = filters

  const data = {
    image: report.image,
    project_id: openProject,
    activeScenario: activeScenario || '',
    activeScenarioPrev: activeScenarioPrev || '',
    activeScenarioNext: activeScenarioNext || '',
    building_filter: bl_id_filter,
    level_filter: fl_id_filter,
    faculty_filter: dv_id_filter,
    department_filter: dp_id_filter,
    type_filter: rm_type_filter,
    function_filter: pr_rm_fu_filter,
    openFilterTab,
  }

  return data
}

export const captureReset = () => async dispatch => {
  dispatch({
    type: 'CAPTURE_RESET',
  })
}
