const buildingCoordinates = {
  ca01: { x: -490, y: -585, z: 0, r: 345 },
  ca02: { x: -445, y: 207, z: 0, r: 9 },
  ca03: { x: -336, y: -14, z: 0, r: 2 },
  ca06: { x: 134, y: 89, z: 28, r: 115 },
  cb01: { x: 0, y: 0, z: -16, r: 25 },
  cb03: { x: 83, y: 1, z: 0, r: 116 },
  cb04: { x: -3, y: 55, z: 0, r: 116 },
  cb05a: { x: 137, y: 332, z: 0, r: 108 },
  cb06: { x: 128, y: 59, z: 0, r: 25 },
  cb07: { x: -82, y: 88, z: 0, r: 25 },
  cb08: { x: 40, y: 315, z: 0, r: 115 },
  cb09: { x: 78, y: 13, z: 0, r: 289 },
  cb10: { x: -185, y: -6, z: 0, r: 24 },
  cb11: { x: -188, y: -35, z: 0, r: 2 },
  cb15: { x: 5, y: 310, z: 0, r: 295 },
  cb16: { x: -272, y: 282, z: 0, r: 295 },
  cb18: { x: 107, y: 50, z: 0, r: 17 },
  cb22: { x: -296, y: -135, z: 0, r: 93 },
  cb23: { x: -285, y: -132, z: 0, r: 273 },
  cb24: { x: -244, y: -108, z: 0, r: 273 },
  cb25: { x: -263, y: -160, z: 0, r: 93 },
  cb26: { x: -234, y: -167, z: 0, r: 93 },
  cb27: { x: -241, y: -202, z: 0, r: 93 },
  cb28: { x: -248, y: -147, z: 0, r: 3 },
  cb61: { x: 300, y: 250, z: 0, r: 75 },
  cb62: { x: 408, y: 313, z: 0, r: 75 },
  cb81: { x: -279, y: 275, z: 0, r: 115 },
  cb91: { x: -90, y: 330, z: 0, r: 25 },
  cb92: { x: -150, y: 430, z: 0, r: 115 },
}

export default buildingCoordinates
