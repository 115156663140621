import React, { useState, Fragment, useEffect } from 'react'
import { string } from 'prop-types'
import { GET_REPORTS } from '@queries'
import { DELETE_REPORT_MUTATION } from '@queries/mutations'
import { useQuery } from '@apollo/react-hooks'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { updateMenuState } from '@redux/actions'
import Spacer from '@components/Spacer/Spacer'
import config from '@config'
import ReportDrawPreview from '@components/ReportDrawPreview/ReportDrawPreview'
import format from 'date-fns/format'
import { useMutation } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import Icon from '@components/Icon/Icon'
import Button from '@material-ui/core/Button'

import './ReportDraw.scss'

const ReportDraw = ({ enqueueSnackbar, updateStore, menus }) => {
  const [selected, setSelected] = useState([])
  const [preview, setPreview] = useState({})

  const { openProject, reportDrawOpen } = menus

  const { data = {}, refetch } = useQuery(GET_REPORTS, {
    variables: { project_id: Number(openProject) },
  })

  const finalList = data.getAllReports || []

  const downloadPdf = async pages => {
    const res = await fetch(`${config.express}/report-pdf`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({
        pages,
      }),
    })

    const blob = await res.blob()

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'report.pdf'
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  const downloadCsv = async pages => {
    const res = await fetch(`${config.express}/report-csv`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({
        pages,
      }),
    })

    const blob = await res.blob()

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'report.csv'
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  const [deleteMutation] = useMutation(DELETE_REPORT_MUTATION)

  const deleteAction = async (e, _id) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      await deleteMutation({ variables: { _id } })
      refetch()
      enqueueSnackbar('Report Deleted', {
        variant: 'success',
      })

      const newSelected = selected.filter(filtered => filtered._id !== _id)
      setSelected(newSelected)
      if (
        !newSelected.map(subItem => subItem._id).includes(String(preview._id))
      ) {
        setPreview({})
      }
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    refetch()
  }, [reportDrawOpen, refetch])

  return (
    <div className="projectDraw__container">
      <div
        className="projectDraw__bg"
        onClick={() =>
          updateStore({
            drawIsOpen: false,
            subDrawIsOpen: false,
            scenarioDrawOpen: false,
            reportDrawOpen: false,
          })
        }
      />

      <div className="projectDraw__nav">
        <div className="projectDraw__nav--scroll scenarioMenu__container">
          <h1 className="projectDraw__header drawInnerWrapper__header">
            Reports
          </h1>

          <Spacer small />

          {finalList.map(item => (
            <div className="drawInnerWrapper__selectContainer ReportDraw__titleContainer">
              <span
                onClick={() => {
                  setSelected([...selected, item])
                }}
              >{`${item.name} - ${format(
                new Date(Number(item.created_at)),
                'dd MMM yyyy',
              )}`}</span>
              <div
                className="ReportDraw__delete"
                onClick={e => deleteAction(e, item._id)}
              >
                <Icon icon={'multiply'} size={16} noPad={true} />
              </div>
            </div>
          ))}

          <Spacer small />
        </div>
      </div>

      <div
        className="projectDraw__nav"
        style={{ width: selected.length > 0 ? '250px' : 0 }}
      >
        <div className="projectDraw__nav--scroll scenarioMenu__container">
          <h1 className="projectDraw__header drawInnerWrapper__header">
            Preview
            <Button
              size="small"
              color="primary"
              className="leftNav__title--button"
              onClick={() => downloadPdf(selected.map(item => item._id))}
            >
              Download All
            </Button>
          </h1>

          <Spacer small />

          {selected.map(item => (
            <Fragment>
              <div
                className="drawInnerWrapper__selectContainer ReportDraw__selectContainer"
                onClick={() => setPreview(item)}
              >
                <div
                  className="ReportDraw__closeButton"
                  onClick={e => {
                    e.stopPropagation()
                    const newSelected = selected.filter(
                      filtered => filtered._id !== item._id,
                    )
                    setSelected(newSelected)
                    if (
                      !newSelected
                        .map(subItem => subItem._id)
                        .includes(String(preview._id))
                    ) {
                      setPreview({})
                    }
                  }}
                >
                  <Icon icon={'multiply'} size={14} noPad={true} />
                </div>
                <img
                  src={`${config.imageServer}/${encodeURI(item.image)}`}
                  alt=""
                />
                <div className="ReportDraw__selectContainerTitle">
                  {item.name}
                </div>
              </div>
              <Spacer small />
            </Fragment>
          ))}
        </div>
      </div>

      {preview.name && (
        <div
          className="projectDraw__nav"
          style={{ width: preview.name ? '650px' : 0 }}
        >
          <div className="projectDraw__nav--scroll scenarioMenu__container">
            <h1 className="projectDraw__header drawInnerWrapper__header">
              Page
              <div>
                <Button
                  size="small"
                  color="primary"
                  className={'leftNav__title--button'}
                  onClick={() => downloadCsv([preview._id])}
                >
                  Download CSV
                </Button>
                <Button
                  size="small"
                  color="primary"
                  className={'leftNav__title--button'}
                  onClick={() => downloadPdf([preview._id])}
                >
                  Download PDF
                </Button>
              </div>
            </h1>

            <Spacer small />

            <ReportDrawPreview item={preview} />

            <Spacer small />
          </div>
        </div>
      )}
    </div>
  )
}

ReportDraw.propTypes = {
  example: string,
}

const mapStateToProps = ({ menus }) => ({ menus })

const mapDispatchToProps = d => ({
  updateStore: obj => d(updateMenuState(obj)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(ReportDraw))
