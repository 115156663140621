import React, { Fragment } from 'react'
import AdminUserList from '@components/AdminUserList/AdminUserList'
import AdminAddUser from '@components/AdminAddUser/AdminAddUser'
import Spacer from '@components/Spacer/Spacer'
import Container from '@material-ui/core/Container'
import PaddingBox from '@components/PaddingBox/PaddingBox'
import AdminSettings from '@pages/AdminSettings/AdminSettings'
import config from '@config'

import './AdminUsers.scss'

const AdminUsers = () => {
  const manualAddUser = () => {
    if (config.env === 'dev') {
      return (
        <Fragment>
          <h1>Add New User</h1>
          <AdminAddUser />
          <Spacer />
        </Fragment>
      )
    }
  }

  return (
    <Fragment>
      <AdminSettings />
      <PaddingBox>
        <Container maxWidth="md">
          {manualAddUser()}
          <h1>User List</h1>
          <AdminUserList />
        </Container>
      </PaddingBox>
    </Fragment>
  )
}

AdminUsers.propTypes = {}

export default AdminUsers
