import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Spacer from '@components/Spacer/Spacer'
import DialogContent from '@material-ui/core/DialogContent'
import { useMutation } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { GET_SCENARIOS } from '@queries'
import {
  EDIT_SCENARIO_DATE_MUTATION,
  NEW_SCENARIO_DATE_MUTATION,
} from '@queries/mutations'
import ColorPicker from '@components/ColorPicker/ColorPicker'

import './NewScenarioDate.scss'

const NewScenarioDate = ({
  enqueueSnackbar,
  client,
  closeModal,
  activeScenarioParent,
  project_id,
  data,
}) => {
  const [formVals, setFormVals] = useState(
    data._id
      ? data
      : {
          name: '',
          date: new Date(),
          comments: '',
          r: '',
          g: '',
          b: '',
        },
  )

  const { name, date, comments, _id, r, g, b } = formVals
  const [newScenarioMutation] = useMutation(
    data._id ? EDIT_SCENARIO_DATE_MUTATION : NEW_SCENARIO_DATE_MUTATION,
  )

  const updateFormVals = (target, val) =>
    setFormVals({ ...formVals, [target]: val })

  const newScenarioAction = async e => {
    e.preventDefault()

    try {
      const variables = {
        name,
        date,
        scenario_id: Number(activeScenarioParent),
        comments,
        _id,
        r: String(r) || String(207),
        g: String(g) || String(207),
        b: String(b) || String(209),
      }

      const res = await newScenarioMutation({
        variables,
      })

      enqueueSnackbar(data._id ? 'Event Edited' : 'Event Created', {
        variant: 'success',
      })

      client.writeQuery({
        query: GET_SCENARIOS,
        data: res,
      })

      closeModal()
      setFormVals({ name: '', date: '', comments: '', r: '', g: '', b: '' })
    } catch (e) {
      console.log(e)
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <DialogTitle>Create Event</DialogTitle>
      <DialogContent>
        <form onSubmit={newScenarioAction}>
          <TextField
            label="Name"
            value={name || ''}
            onChange={e => updateFormVals('name', e.target.value)}
            margin="normal"
            type="text"
            required
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              label="Date"
              value={date}
              onChange={e => updateFormVals('date', e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>

          <TextField
            label="Comment"
            value={comments || ''}
            onChange={e => updateFormVals('comments', e.target.value)}
            margin="normal"
            type="text"
          />

          <Spacer />

          <ColorPicker
            setColor={({ r, g, b }) => setFormVals({ ...formVals, r, g, b })}
            color={{ r, g, b }}
          />

          <Spacer />

          <Button variant="contained" color="primary" type="submit">
            Save New Event
          </Button>
        </form>
      </DialogContent>
    </div>
  )
}

NewScenarioDate.propTypes = {}

export default withSnackbar(withApollo(NewScenarioDate))
