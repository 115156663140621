import React, { Component } from 'react'
import { string } from 'prop-types'
import get from 'lodash.get'
import { withApollo } from 'react-apollo'
import forgeApi from '@forge'
import { connect } from 'react-redux'
import { storeBuildingNodes, resetAllState } from '@redux/actions'
import { GET_TOKEN } from '@queries'
import { withSnackbar } from 'notistack'
import CircularProgress from '@material-ui/core/CircularProgress'

import './ForgeViewer.scss'

class ForgeViewer extends Component {
  constructor(props) {
    super(props)
    this.saveData = this.saveData.bind(this)
    this.snackbar = this.snackbar.bind(this)
  }

  async componentDidMount() {
    const { data } = await this.props.client.query({ query: GET_TOKEN })
    const token = get(data, 'getToken.token')
    const expireTime = get(data, 'getToken.expires_in')
    this.setState({ token, expireTime })
    this.snackbar()
  }

  state = {
    token: '',
    expireTime: '',
  }

  componentDidUpdate(prevProps, prevState) {
    const { token } = this.state
    const { urnList, activeProject } = this.props

    const oldUrns = JSON.stringify(prevProps.urnList)
    const newUrns = JSON.stringify(urnList)

    if (!activeProject || urnList.length === 0) {
      return forgeApi.finished()
    }
    // compare loaded models to check if need to repload project
    if (token && urnList.length > 0 && oldUrns !== newUrns) {
      const jsScript = document.createElement('script')
      jsScript.id = 'js_dom_target'
      jsScript.src =
        'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.10/viewer3D.min.js'

      document.body.appendChild(jsScript)

      jsScript.addEventListener('load', () =>
        forgeApi.runForge(
          this.state.token,
          this.state.expireTime,
          this.saveData,
          urnList,
        ),
      )
    }
  }

  saveData(buildingNodes) {
    this.props.update(buildingNodes)
  }

  snackbar() {
    forgeApi.saveSnack(this.props.enqueueSnackbar)
  }

  componentWillUnmount() {
    const elem = document.getElementById('js_dom_target')
    if (elem) {
      elem.parentNode.removeChild(elem)
    }
    forgeApi.finished()
    this.props.reset()
  }

  render() {
    const { currentModelLoading, lengthOfAllModels } = this.props.menus
    const showLoader =
      lengthOfAllModels > 0 && lengthOfAllModels !== currentModelLoading

    return (
      <div className="forge__container">
        {showLoader && (
          <div className="forge__loadingContainer">
            <div className="forge__loadingInner">
              <div className="forge__progressBar">
                <CircularProgress color="white" size={21} />
              </div>
              Loading {currentModelLoading + 1} of {lengthOfAllModels} Buildings
            </div>
          </div>
        )}
        Please open a project
        <div id="forgeViewer"></div>
      </div>
    )
  }
}

ForgeViewer.propTypes = {
  example: string,
}

const mapStateToPrpos = ({ menus }) => ({ menus })

const mapDispatchToProps = dispatch => ({
  update: data => dispatch(storeBuildingNodes(data)),
  reset: () => dispatch(resetAllState()),
})

export default connect(
  mapStateToPrpos,
  mapDispatchToProps,
)(withSnackbar(withApollo(ForgeViewer)))
