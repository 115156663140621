import React, { useEffect } from 'react'
import LeftNav from '@components/LeftNav/LeftNav'
import ProjectDraw from '@components/ProjectDraw/ProjectDraw'
import Fader from '@components/Fader/Fader'
import { withRouter } from 'react-router-dom'
import DrawInnerWrapper from '@components/DrawInnerWrapper/DrawInnerWrapper'
import { connect } from 'react-redux'
import { fetchData, updateMenuState } from '@redux/actions'
import ReportDraw from '@components/ReportDraw/ReportDraw'

import './Container.scss'

const Container = ({ children, updateStore, history, fetch, menus }) => {
  const { drawIsOpen, scenarioDrawOpen, reportDrawOpen } = menus

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      history.push('/login')
    }
  }, [history])

  useEffect(() => {
    fetch()
  }, [fetch])

  return (
    <div className="container__body">
      <Fader show={drawIsOpen}>
        <ProjectDraw
          open={drawIsOpen}
          setOpenState={() => updateStore({ drawIsOpen: !drawIsOpen })}
        />
      </Fader>

      <Fader show={scenarioDrawOpen}>
        <DrawInnerWrapper
          open={scenarioDrawOpen}
          setOpenState={() =>
            updateStore({ scenarioDrawOpen: !scenarioDrawOpen })
          }
        />
      </Fader>

      <Fader show={reportDrawOpen}>
        <ReportDraw
          open={reportDrawOpen}
          setOpenState={() => updateStore({ reportDrawOpen: !reportDrawOpen })}
        />
      </Fader>

      <div className="container__left">
        <LeftNav />
      </div>
      <div className="container__right">{children}</div>
    </div>
  )
}

Container.propTypes = {}

const mapStateToProps = ({ menus }) => ({ menus })

const mapDispatchToProps = d => ({
  fetch: () => d(fetchData()),
  updateStore: obj => d(updateMenuState(obj)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Container))
