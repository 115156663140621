import React, { useState, useEffect } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import forge from '@forge'
import './SearchBoxResults.scss'

const SearchBoxResults = ({
  results,
  searchTerm,
  setUpdateChildrenId,
  lookUpDate,
  clearSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (searchTerm.length > 0) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [searchTerm])

  return (
    isOpen && (
      <div className="serachBox__results">
        <div className="serachBox__results--inner">
          {results.length > 0 ? (
            results.map(title => {
              return (
                <div
                  className={cx('searchBox__item', {})}
                  key={'searchRes' + title}
                  onClick={() => {
                    const { dbId, modelId, bl_id, level } = lookUpDate[title]
                    setIsOpen(false)
                    setUpdateChildrenId([
                      `${bl_id}`,
                      `${bl_id}${level}`,
                      `${bl_id}${level}${dbId}`,
                    ])
                    clearSearch()
                    forge.showId([dbId], modelId)
                  }}
                >
                  {title}
                </div>
              )
            })
          ) : (
            <div className={cx('searchBox__item')}>No results</div>
          )}
        </div>
      </div>
    )
  )
}
SearchBoxResults.propTypes = {
  example: string,
}

export default SearchBoxResults
