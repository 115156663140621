import React, { useState } from 'react'
import { string } from 'prop-types'
import Button from '@material-ui/core/Button'
import Fader from '@components/Fader/Fader'
import { useQuery } from '@apollo/react-hooks'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Dialog from '@material-ui/core/Dialog'
import NewProjectModal from '@modals/NewProjectModal/NewProjectModal'
import { GET_PROJECTS } from '@queries'
import { withSnackbar } from 'notistack'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import EditProjectDetails from '@components/EditProjectDetails/EditProjectDetails'
import config from '@config'
import { connect } from 'react-redux'
import { updateMenuState } from '@redux/actions'

import './ProjectDraw.scss'

const ProjectDraw = ({ enqueueSnackbar, menus, updateStore }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const [active, setActive] = useState({
    name: '',
    _id: '',
    description: '',
    address: '',
    picture: '',
  })

  const { data: projectData = [] } = useQuery(GET_PROJECTS)
  const projects = projectData.getProjects || []

  const { subDrawIsOpen } = menus
  const { _id: activeId } = active

  return (
    <div className="projectDraw__container">
      <div
        className="projectDraw__bg"
        onClick={() =>
          updateStore({
            drawIsOpen: false,
            subDrawIsOpen: false,
          })
        }
      />
      <div className="projectDraw__nav">
        <div className="projectDraw__nav--scroll">
          <h1 className="projectDraw__header">
            Projects{' '}
            <Button color="secondary" onClick={() => setModalOpen(true)}>
              Create New
            </Button>
          </h1>
          {projects.map(({ _id, name, description, address, picture }) => (
            <div
              className="project__navItem"
              key={_id}
              onClick={() => {
                // load model

                updateStore({
                  drawIsOpen: false,
                  openProject: _id,
                })
              }}
            >
              <div className="project__navPicture">
                {!!picture ? (
                  <div
                    className="project__navPicture--image"
                    style={{
                      backgroundImage: `url('${config.imageServer}/${encodeURI(
                        picture,
                      )}')`,
                    }}
                  />
                ) : (
                  <div className="project__navPicture--placeholder">
                    <AccountTreeIcon />
                  </div>
                )}
              </div>
              <div className="project__navInfo">
                <div className="project__navTitle">{name}</div>
                <div className="project__navDesc">{description}</div>
              </div>
              <div
                className="project__navMore"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setActive({ _id, name, description, address, picture })
                  if (!activeId || activeId === _id) {
                    updateStore({
                      subDrawIsOpen: !subDrawIsOpen,
                    })
                  }
                }}
              >
                <MoreVertIcon />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="projectDraw__body">
        <Fader show={subDrawIsOpen}>
          <EditProjectDetails
            active={active}
            setActive={setActive}
            closeDraw={() =>
              updateStore({
                subDrawIsOpen: false,
              })
            }
          />
        </Fader>
      </div>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        maxWidth="md"
        fullWidth
      >
        <NewProjectModal closeModal={() => setModalOpen(false)} />
      </Dialog>
    </div>
  )
}

ProjectDraw.propTypes = {
  example: string,
}

const mapStateToProps = ({ menus }) => ({ menus })

const mapDispatchToProps = d => ({
  updateStore: obj => d(updateMenuState(obj)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(ProjectDraw))
