import React, { Fragment, useState } from 'react'
import { string } from 'prop-types'
import { withApollo } from 'react-apollo'
import BuildingAccordion from '@components/BuildingAccordion/BuildingAccordion'
import RoomInfoTabs from '@components/RoomInfoTabs/RoomInfoTabs'
import SearchBox from '@components/SearchBox/SearchBox'
import Filters from '@components/Filters/Filters'
import { connect } from 'react-redux'

import './PopMenu.scss'

const PopMenu = ({ client, nodes }) => {
  const [updateChildrenId, setUpdateChildrenId] = useState([])

  return (
    <div className="popMenu__container">
      {true ? (
        <Fragment>
          <SearchBox setUpdateChildrenId={setUpdateChildrenId} />
          <div className="popMenu__inner">
            <BuildingAccordion updateChildrenId={updateChildrenId} />
            <Filters />
            <RoomInfoTabs />
          </div>
        </Fragment>
      ) : (
        <div>Please open a project</div>
      )}
    </div>
  )
}

PopMenu.propTypes = {
  example: string,
}

const mapStateToProps = ({ nodes }) => ({ nodes })

export default connect(
  mapStateToProps,
  null,
)(withApollo(PopMenu))
