import React, { useState } from 'react'
import { string } from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Spacer from '@components/Spacer/Spacer'
import DialogContent from '@material-ui/core/DialogContent'
import { useMutation } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import { GET_PROJECTS } from '@queries'
import ImageUploader from '@components/ImageUploader/ImageUploader'
import { NEW_PROJECT_MUTATION } from '@queries/mutations'

import './NewProjectModal.scss'

const NewProjectModal = ({ enqueueSnackbar, client, closeModal }) => {
  const [formVals, setFormVals] = useState({
    name: '',
    description: '',
    address: '',
  })
  const [picture, setPicture] = useState()

  const { name, description, address } = formVals
  const [newProjectMutation] = useMutation(NEW_PROJECT_MUTATION)

  const updateFormVals = (target, val) =>
    setFormVals({ ...formVals, [target]: val })

  const newProjectAction = async e => {
    e.preventDefault()

    try {
      const variables = { name, description, address }

      if (picture && picture.length) {
        variables.imageUpload = picture[0]
      }

      const res = await newProjectMutation({
        variables,
      })

      enqueueSnackbar('Project Created', {
        variant: 'success',
      })
      const projectData = client.readQuery({ query: GET_PROJECTS })

      client.writeQuery({
        query: GET_PROJECTS,
        data: {
          getProjects: [...projectData.getProjects, res.data.createProject],
        },
      })
      closeModal()
      setFormVals({ name: '', description: '', address: '' })
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <DialogTitle>Create New Project</DialogTitle>
      <DialogContent>
        <form onSubmit={newProjectAction}>
          <TextField
            label="Name"
            value={name}
            onChange={e => updateFormVals('name', e.target.value)}
            margin="normal"
            type="text"
            required
          />

          <TextField
            label="Description"
            value={description}
            onChange={e => updateFormVals('description', e.target.value)}
            margin="normal"
            type="text"
          />

          <Spacer />

          <p>Cover Image</p>

          <ImageUploader callback={e => setPicture(e)} />

          <Spacer />

          <Button variant="contained" color="primary" type="submit">
            Save New Project
          </Button>
        </form>
      </DialogContent>
    </div>
  )
}

NewProjectModal.propTypes = {
  example: string,
}

export default withSnackbar(withApollo(NewProjectModal))
