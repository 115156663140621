import React, { useState, Fragment } from 'react'
import { object } from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { useQuery } from '@apollo/react-hooks'
import { GET_FILTER_DATA } from '@queries'
import { updateFiltersState, resetAllFilters } from '@redux/actions'
import { connect } from 'react-redux'

import './Filters.scss'

const EMPTY_FILTERS = {
  bl_id: '',
  fl_id: '',
  dv_id: '',
  dp_id: '',
  rm_type: '',
  pr_rm_fu: '',
}

const Filters = ({ updateFilters, resetFilters, menus, labels }) => {
  const [filters, setFilters] = useState(EMPTY_FILTERS)
  const { data: { getFilterData = {} } = {}, refetch } = useQuery(
    GET_FILTER_DATA,
    {
      variables: { project_id: menus.openProject },
      fetchPolicy: 'network-only',
    },
  )

  const {
    buildings = [],
    levels = [],
    faculties = [],
    departments = [],
    types = [],
    functions = [],
  } = getFilterData

  const clickAction = (target, value) => {
    setFilters({ ...filters, [target]: value })
    updateFilters({
      [target + '_filter']: value,
    })
  }

  const findLabel = (labelSelect, target, key) => {
    const data = labels[labelSelect] || []
    const correctNode = data.find(item => item[target] === key) || {}
    const textLabel =
      correctNode.name || correctNode.description || correctNode.pr_rm_desc
    return textLabel
  }

  return (
    <Fragment>
      <h1 className="leftNav__title leftNav__title--topPad">
        Filters
        <span
          className="leftNav__title--button"
          onClick={() => {
            setFilters(EMPTY_FILTERS)
            resetFilters()
            refetch()
          }}
        >
          Clear Filter
        </span>
      </h1>
      <div className="filter__container">
        <div className="filter__subContainer">
          <FormControl>
            <InputLabel id="building_label">Building</InputLabel>
            <Select
              labelId="building_label"
              onChange={e => clickAction('bl_id', e.target.value)}
              value={filters.bl_id || ''}
            >
              <MenuItem value={''}>None</MenuItem>
              {[...buildings]
                .sort((a, b) => a.localeCompare(b))
                .map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="level_label">Level</InputLabel>
            <Select
              labelId="level_label"
              onChange={e => clickAction('fl_id', e.target.value)}
              value={filters.fl_id || ''}
            >
              <MenuItem value={''}>None</MenuItem>

              {[...levels]
                .sort((a, b) => a.localeCompare(b))
                .map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="filter__subContainer">
          <FormControl>
            <InputLabel id="faculty_label">Faculty</InputLabel>
            <Select
              labelId="faculty_label"
              onChange={e => clickAction('dv_id', e.target.value)}
              value={filters.dv_id || ''}
            >
              <MenuItem value={''}>None</MenuItem>
              {[...faculties]
                .sort((a, b) => a.localeCompare(b))
                .map(item => (
                  <MenuItem value={item} key={item}>
                    {item} - {findLabel('dv', 'dv_id', item)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="department_label">Department</InputLabel>
            <Select
              labelId="department_label"
              onChange={e => clickAction('dp_id', e.target.value)}
              value={filters.dp_id || ''}
            >
              <MenuItem value={''}>None</MenuItem>
              {[...departments]
                .sort((a, b) => a.localeCompare(b))
                .map(item => (
                  <MenuItem value={item} key={item}>
                    {item} - {findLabel('dp', 'dp_id', item)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="filter__subContainer">
          <FormControl>
            <InputLabel id="type_label">Primary Type</InputLabel>
            <Select
              labelId="type_label"
              onChange={e => clickAction('rm_type', e.target.value)}
              value={filters.rm_type || ''}
            >
              <MenuItem value={''}>None</MenuItem>
              {[...types]
                .sort((a, b) => a.localeCompare(b))
                .map(item => (
                  <MenuItem value={item} key={item}>
                    {item} - {findLabel('rmtype', 'rm_type', item)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="type_label">Primary Function</InputLabel>
            <Select
              labelId="type_label"
              onChange={e => clickAction('pr_rm_fu', e.target.value)}
              value={filters.pr_rm_fu || ''}
            >
              <MenuItem value={''}>None</MenuItem>
              {[...functions]
                .sort((a, b) => a.localeCompare(b))
                .map(item => (
                  <MenuItem value={item} key={item}>
                    {item} - {findLabel('rmpfun', 'pr_rm_fu', item)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </Fragment>
  )
}

Filters.propTypes = {
  client: object,
}

const mapStateToProps = ({ menus, labels }) => ({ menus, labels })

const mapDispatchToProps = d => ({
  updateFilters: obj => d(updateFiltersState(obj)),
  resetFilters: () => d(resetAllFilters()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters)
