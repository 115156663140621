import React from 'react'
import { func } from 'prop-types'
import Spacer from '@components/Spacer/Spacer'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks'
import getId from '@helpers/getId'
import stringifyErrors from '@helpers/stringifyErrors'
import { GET_USER } from '@queries'
import { USER_MUTATION } from '@queries/mutations'

import './ProfileChangeDetails.scss'

const ProfileChangeDetails = ({ enqueueSnackbar, client }) => {
  const userId = getId()
  const { data = {} } = useQuery(GET_USER, {
    variables: { _id: userId },
  })
  const [editUserMutation] = useMutation(USER_MUTATION)

  const user = data.getUser || {}
  const { firstName = '', lastName = '', email = '', _id = '' } = user

  const changeUserAction = async e => {
    try {
      e.preventDefault()
      await editUserMutation({
        variables: { _id, firstName, lastName, email },
      })
      enqueueSnackbar('User Updated', {
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  const updateState = (target, value) => {
    client.writeFragment({
      id: _id,
      fragment: gql`
        fragment getUser on GetUser {
          ${target}
        }
      `,
      data: { ...user, [target]: value },
    })
  }

  return (
    <Grid>
      <form onSubmit={changeUserAction}>
        <Grid item>
          <TextField
            label="First Name"
            value={firstName}
            onChange={e => updateState('firstName', e.target.value)}
            margin="normal"
            type="text"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Last Name"
            value={lastName}
            onChange={e => updateState('lastName', e.target.value)}
            margin="normal"
            type="text"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Email"
            value={email}
            onChange={e => updateState('email', e.target.value.toLowerCase())}
            margin="normal"
            type="email"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Spacer />
      </form>
    </Grid>
  )
}

ProfileChangeDetails.propTypes = {
  enqueueSnackbar: func,
}

export default withSnackbar(withApollo(ProfileChangeDetails))
