import gql from 'graphql-tag'

export const REGISTER_MUTATION = gql`
  mutation Register($email: String!, $admin: Boolean!, $password: String!) {
    register(email: $email, admin: $admin, password: $password) {
      _id
      email
      admin
      givenname
      surname
    }
  }
`

export const PROJECT_MUTATION = gql`
  mutation UpdateProject(
    $_id: String!
    $name: String!
    $description: String!
    $imageUpload: Upload
  ) {
    updateProject(
      _id: $_id
      name: $name
      description: $description
      imageUpload: $imageUpload
    ) {
      _id
      name
      description
      picture
    }
  }
`

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($_id: String!) {
    deleteUser(_id: $_id) {
      _id
      email
      admin
      givenname
      surname
    }
  }
`

export const USER_MUTATION = gql`
  mutation EditMyUser(
    $_id: String!
    $givenname: String!
    $surname: String!
    $email: String!
  ) {
    editMyUser(
      _id: $_id
      givenname: $givenname
      surname: $surname
      email: $email
    ) {
      givenname
      surname
      email
    }
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmNewPassword: String!
    $_id: String!
  ) {
    updatePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmNewPassword: $confirmNewPassword
      _id: $_id
    ) {
      _id
    }
  }
`

export const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($_id: String!) {
    deleteProject(_id: $_id) {
      _id
      name
      description
    }
  }
`

export const COLOR_MUTATION = gql`
  mutation EditColor(
    $_id: String
    $name: String!
    $type: String!
    $r: Int!
    $g: Int!
    $b: Int!
    $project_id: String!
  ) {
    editColor(
      _id: $_id
      name: $name
      type: $type
      r: $r
      g: $g
      b: $b
      project_id: $project_id
    ) {
      _id
      name
      type
      r
      g
      b
      project_id
    }
  }
`

export const DELETE_BUILDING_MUTATION = gql`
  mutation DeleteBuilding($_id: String!) {
    deleteBuilding(_id: $_id) {
      _id
      project_id
      name
      description
      address
      picture
      cord_x
      cord_y
      cord_z
      rotation
      urn
    }
  }
`

export const NEW_BUILDING_MUTATION = gql`
  mutation CreateBuilding(
    $project_id: String!
    $name: String!
    $description: String!
    $address: String
    $cord_x: Int
    $cord_y: Int
    $cord_z: Int
    $rotation: Int
    $imageUpload: Upload
    $urn: Int
  ) {
    createBuilding(
      name: $name
      project_id: $project_id
      description: $description
      address: $address
      cord_x: $cord_x
      cord_y: $cord_y
      cord_z: $cord_z
      rotation: $rotation
      imageUpload: $imageUpload
      urn: $urn
    ) {
      _id
      project_id
      name
      description
      address
      picture
      cord_x
      cord_y
      cord_z
      rotation
      urn
    }
  }
`

export const EDIT_BUILDING_MUTATION = gql`
  mutation UpdateBuilding(
    $_id: String!
    $name: String!
    $description: String!
    $address: String
    $cord_x: Int
    $cord_y: Int
    $cord_z: Int
    $rotation: Int
    $imageUpload: Upload
    $urn: Int
  ) {
    updateBuilding(
      name: $name
      _id: $_id
      description: $description
      address: $address
      cord_x: $cord_x
      cord_y: $cord_y
      cord_z: $cord_z
      rotation: $rotation
      imageUpload: $imageUpload
      urn: $urn
    ) {
      _id
      project_id
      name
      description
      address
      picture
      cord_x
      cord_y
      cord_z
      rotation
      urn
    }
  }
`

export const NEW_PROJECT_MUTATION = gql`
  mutation CreateProject(
    $name: String!
    $description: String!
    $imageUpload: Upload
  ) {
    createProject(
      name: $name
      description: $description
      imageUpload: $imageUpload
    ) {
      _id
      name
      description
      picture
    }
  }
`

export const NEW_SCENARIO_MUTATION = gql`
  mutation NewScenario($name: String!, $project_id: Int!) {
    newScenario(name: $name, project_id: $project_id) {
      _id
      name
      project_id
    }
  }
`

export const EDIT_SCENARIO_MUTATION = gql`
  mutation EditScenario($name: String!, $_id: Int!) {
    editScenario(name: $name, _id: $_id) {
      _id
      name
      project_id
    }
  }
`

export const NEW_SCENARIO_DATE_MUTATION = gql`
  mutation NewScenarioDate(
    $name: String!
    $date: String!
    $scenario_id: Int!
    $comments: String
    $r: String
    $g: String
    $b: String
  ) {
    newScenarioDate(
      name: $name
      date: $date
      scenario_id: $scenario_id
      comments: $comments
      r: $r
      g: $g
      b: $b
    ) {
      _id
      name
      project_id
      scenario_dates {
        _id
        name
        scenario_id
        date
        comments
        r
        g
        b
      }
    }
  }
`

export const EDIT_SCENARIO_DATE_MUTATION = gql`
  mutation EditScenarioDate(
    $name: String!
    $date: String!
    $scenario_id: Int!
    $_id: String!
    $comments: String
    $r: String
    $g: String
    $b: String
  ) {
    editScenarioDate(
      name: $name
      date: $date
      _id: $_id
      scenario_id: $scenario_id
      comments: $comments
      r: $r
      g: $g
      b: $b
    ) {
      _id
      name
      project_id
      scenario_dates {
        _id
        name
        scenario_id
        date
        comments
        r
        g
        b
      }
    }
  }
`

export const DELETE_SCENARIO_DATE_MUTATION = gql`
  mutation DeleteScenarioDate($_id: String!) {
    deleteScenarioDate(_id: $_id) {
      _id
      name
      project_id
      scenario_dates {
        _id
        name
        scenario_id
        date
        comments
      }
    }
  }
`

export const DELETE_SCENARIO_MUTATION = gql`
  mutation DeleteScenario($_id: Int!) {
    deleteScenario(_id: $_id) {
      _id
      name
      project_id
      scenario_dates {
        _id
        name
        scenario_id
        date
        comments
      }
    }
  }
`

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`

export const UPLOAD_REPORT_MUTATION = gql`
  mutation UploadReport(
    $name: String!
    $image: Upload!
    $project_id: String!
    $activeScenario: String
    $activeScenarioPrev: String
    $activeScenarioNext: String
    $building_filter: String
    $level_filter: String
    $faculty_filter: String
    $department_filter: String
    $type_filter: String
    $function_filter: String
    $openFilterTab: Int
  ) {
    uploadReport(
      name: $name
      image: $image
      project_id: $project_id
      activeScenario: $activeScenario
      activeScenarioPrev: $activeScenarioPrev
      activeScenarioNext: $activeScenarioNext
      building_filter: $building_filter
      level_filter: $level_filter
      faculty_filter: $faculty_filter
      department_filter: $department_filter
      type_filter: $type_filter
      function_filter: $function_filter
      openFilterTab: $openFilterTab
    ) {
      _id
      name
      image
      project_id
      activeScenario
      activeScenarioPrev
      activeScenarioNext
      building_filter
      level_filter
      faculty_filter
      department_filter
      type_filter
      function_filter
      openFilterTab
      activeScenarioParent
      scenario_name
      event_name
    }
  }
`

export const DELETE_REPORT_MUTATION = gql`
  mutation DeleteReport($_id: String!) {
    deleteReport(_id: $_id) {
      _id
      name
      image
      project_id
      activeScenario
      activeScenarioPrev
      activeScenarioNext
      building_filter
      level_filter
      faculty_filter
      department_filter
      type_filter
      function_filter
      openFilterTab
      activeScenarioParent
      scenario_name
    }
  }
`

export const UPLOAD_FILES = gql`
  mutation UploadFile($file: Upload!, $type: String) {
    uploadFile(file: $file, type: $type)
  }
`
