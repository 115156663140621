import React, { Fragment, useState } from 'react'
import { string } from 'prop-types'
import Button from '@material-ui/core/Button'
import { useQuery } from '@apollo/react-hooks'
import Dialog from '@material-ui/core/Dialog'
import NewBuildingModal from '@modals/NewBuildingModal/NewBuildingModal'
import { GET_BUILDINGS } from '@queries'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import config from '@config'

import './BuildingList.scss'

const BuildingList = ({ projectId }) => {
  const [activeBuilding, setActiveBuilding] = useState({})
  const [newBuildingModalOpen, setNewBuildingModalOpen] = useState(false)

  const { data = {}, refetch } = useQuery(GET_BUILDINGS, {
    variables: { project_id: projectId },
  })

  let { getBuildingsByProject = [] } = data
  const buildingsByProject = [...getBuildingsByProject].sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
  )

  return (
    <Fragment>
      <h1 className="projectDraw__header">
        Buildings{' '}
        <Button
          color="secondary"
          onClick={() => {
            setActiveBuilding({})
            setNewBuildingModalOpen(true)
          }}
        >
          New Building
        </Button>
      </h1>
      <div className="buildingList__buildingContainer">
        {buildingsByProject.map(item => (
          <div
            className="buildingList__building"
            key={item._id}
            onClick={() => {
              setActiveBuilding(item)
              setNewBuildingModalOpen(true)
            }}
          >
            <div className="buildingList__pictureContainer">
              {!!item.picture ? (
                <div
                  className="buildingList__picture"
                  style={{
                    backgroundImage: `url('${config.imageServer}/${encodeURI(
                      item.picture,
                    )}')`,
                  }}
                />
              ) : (
                <LocationCityIcon />
              )}
            </div>
            <div className="buildingList__textContainer">
              <h2>{item.name}</h2>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>

      <Dialog
        open={newBuildingModalOpen}
        onClose={() => setNewBuildingModalOpen(!newBuildingModalOpen)}
        maxWidth="sm"
        fullWidth
      >
        <NewBuildingModal
          closeModal={() => setNewBuildingModalOpen(false)}
          projectId={projectId}
          activeBuilding={activeBuilding}
          callback={refetch}
        />
      </Dialog>
    </Fragment>
  )
}
BuildingList.propTypes = {
  activeId: string,
}

export default BuildingList
