import React, { useState, useEffect, Fragment } from 'react'
import { func, object } from 'prop-types'
import queryString from 'query-string'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Spacer from '@components/Spacer/Spacer'
import logo from '@assets/uts_dark.png'
import { useMutation } from 'react-apollo'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import stringifyErrors from '@helpers/stringifyErrors'
import { updateUser } from '@redux/actions'
import { connect } from 'react-redux'
import { LOGIN_MUTATION } from '@queries/mutations'
import config from '@config'

import './Login.scss'

const Login = ({ enqueueSnackbar, history, updateToken, location }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginMutate] = useMutation(LOGIN_MUTATION)

  const loginAction = async e => {
    try {
      e.preventDefault()
      const res = await loginMutate({ variables: { email, password } })
      updateToken(res.data.login.token)
      localStorage.setItem('token', res.data.login.token)
      localStorage.setItem('email', res.data.login.email)
      enqueueSnackbar('Welcome', {
        variant: 'success',
      })
      history.push('/viewer')
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  const manualLoginForm = () => {
    if (config.env === 'dev' || config.env === 'test') {
      return (
        <Fragment>
          <TextField
            label="Email"
            className="loginPage__loginInput"
            value={email}
            onChange={e => setEmail(e.target.value)}
            margin="normal"
            type="email"
            required
          />

          <TextField
            label="Password"
            className="loginPage__loginInput"
            value={password}
            onChange={e => setPassword(e.target.value)}
            margin="normal"
            type="password"
            required
          />

          <Spacer />

          <Button variant="contained" color="primary" type="submit">
            Dev Login
          </Button>
        </Fragment>
      )
    }
  }

  useEffect(() => {
    if (location.search) {
      const param = queryString.parse(location.search)
      if (param.token) {
        updateToken(param.token)
        localStorage.setItem('token', param.token)
        localStorage.setItem('email', param.email)
        enqueueSnackbar('Welcome', {
          variant: 'success',
        })
        history.push('/viewer')
      }
    }
    const token = localStorage.getItem('token')
    if (token) {
      updateToken(token)
      history.push('/')
    }
  }, [history, updateToken, location.search, enqueueSnackbar])

  return (
    <div className="loginPage__container">
      <div className="loginPage__container--float">
        <img src={logo} alt="uts logo" className="loginPage__logo" />
        <form onSubmit={loginAction}>
          {manualLoginForm()}
          <Spacer />
          <Spacer />
          <Button href={config.login} variant="contained" color="primary">
            Login
          </Button>
        </form>
      </div>
    </div>
  )
}

Login.propTypes = { enqueueSnackbar: func, history: object }

const mapDispatchToProps = d => ({
  updateToken: token => d(updateUser(token)),
})

export default connect(
  null,
  mapDispatchToProps,
)(withSnackbar(withRouter(Login)))
