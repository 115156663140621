const production = {
  apiServer: 'https://3dvisualisationeb.uts.edu.au/graphql', // eb
  express: 'https://3dvisualisationeb.uts.edu.au', // eb
  imageServer: 'https://3dvisualisation.uts.edu.au/images', // s3
  login: 'https://3dvisualisationeb.uts.edu.au/saml-login',
  logout: 'https://3dvisualisationeb.uts.edu.au/saml-logout',
  env: 'prod',
}

const test = {
  apiServer: 'https://test-3dvisualisationeb.uts.edu.au/graphql', // eb
  express: 'https://test-3dvisualisationeb.uts.edu.au/', // eb
  imageServer: 'https://test-3dvisualisation.uts.edu.au/images', // s3
  login: 'https://test-3dvisualisationeb.uts.edu.au/saml-login',
  logout: 'https://test-3dvisualisationeb.uts.edu.au/saml-logout',
  env: 'test',
}

const dev = {
  apiServer: 'http://localhost:4000/graphql',
  express: 'http://localhost:4000',
  imageServer: 'http://localhost:4000/static',
  login: 'https://test-3dvisualisationeb.uts.edu.au/saml-login',
  logout: 'https://test-3dvisualisationeb.uts.edu.au/saml-logout',
  env: 'dev',
}

const AWSENV = process.env.AWS_ENV

const getConfig = () => {
  switch (AWSENV) {
    case 'production':
      return production
    case 'test':
      return test
    default:
      return dev
  }
}

const config = getConfig()

export default config
