import { combineReducers } from 'redux'

// should move this to seperate files if it gets big

const defaultLabelState = {
  dp: [],
  dv: [],
  rmpfun: [],
  rmcat: [],
  rmfun: [],
  rmfuntype: [],
  rmtype: [],
}

const defaultMenuState = {
  drawIsOpen: false,
  subDrawIsOpen: false,
  expandDrawOpen: false,
  scenarioDrawOpen: false,
  reportDrawOpen: false,
  activeScenario: false,
  activeScenarioPrev: false,
  activeScenarioNext: false,
  activeScenarioParent: false,
  color_toggle: false,
  openProject: '',
  currentModelLoading: 0,
  lengthOfAllModels: 0,
}

const defaultFiltersState = {
  bl_id_filter: '',
  fl_id_filter: '',
  dv_id_filter: '',
  dp_id_filter: '',
  rm_type_filter: '',
  pr_rm_fu_filter: '',
  openFilterTab: 1,
}

const defaultNodesState = {
  buildingNodes: [],
}

const defaultUser = {
  token: undefined,
}

const defaultReportstate = {
  reportNameModalOpen: false,
  name: '',
  data: '',
  image: '',
}

const report = (state = defaultReportstate, action) => {
  switch (action.type) {
    case 'CAPTURE_UPDATE':
      return {
        ...state,
        image: action.image,
        reportNameModalOpen: action.reportNameModalOpen,
      }
    case 'CAPTURE_RESET':
      return defaultReportstate
    case 'RESET':
      return defaultReportstate
    default:
      return state
  }
}

const labels = (state = defaultLabelState, action) => {
  switch (action.type) {
    case 'STORE_LABELS':
      return {
        ...state,
        dp: action.dp,
        dv: action.dv,
        rmpfun: action.rmpfun,
        rmcat: action.rmcat,
        rmfun: action.rmfun,
        rmfuntype: action.rmfuntype,
        rmtype: action.rmtype,
      }
    case 'RESET':
      return defaultLabelState
    default:
      return state
  }
}

const menus = (state = defaultMenuState, action) => {
  switch (action.type) {
    case 'MENU_STATE':
      return {
        ...state,
        ...action.menuState,
      }
    case 'RESET':
      return defaultMenuState
    default:
      return state
  }
}

const filters = (state = defaultFiltersState, action) => {
  switch (action.type) {
    case 'FILTER_STATE':
      return {
        ...state,
        ...action.filters,
      }

    case 'FILTER_STATE_RESET':
      return defaultFiltersState

    case 'RESET':
      return defaultFiltersState
    default:
      return state
  }
}

const nodes = (state = defaultNodesState, action) => {
  switch (action.type) {
    case 'STORE_BUILDING_NODES':
      return {
        ...state,
        buildingNodes: action.nodes,
      }
    case 'RESET':
      return defaultNodesState
    default:
      return state
  }
}

const user = (state = defaultUser, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        ...state,
        token: action.token,
      }
    case 'LOGOUT':
      return defaultUser
    default:
      return state
  }
}

export default combineReducers({
  labels,
  menus,
  filters,
  nodes,
  user,
  report,
})
