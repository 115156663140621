import React, { useState, useEffect } from 'react'
import SplitPane from 'react-split-pane'
import { useQuery } from '@apollo/react-hooks'
import ForgeViewer from '@components/ForgeViewer/ForgeViewer'
import PopMenu from '@components/PopMenu/PopMenu'
import { GET_BUILDINGS } from '@queries'
import forge from '@forge'
import Scenario from '@components/Scenario/Scenario'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { captureReset } from '@redux/actions'
import CaptureReportModal from '@modals/CaptureReportModal/CaptureReportModal'

import './Viewer.scss'

const MIN_SIZE = 400

const Viewer = ({ menus, resetModal, report }) => {
  const [size, setSize] = useState(500)
  const { expandDrawOpen, openProject } = menus
  const { reportNameModalOpen } = report
  const finalSize = expandDrawOpen ? size : 0

  const { data: buildingProjects = {} } = useQuery(GET_BUILDINGS, {
    variables: { project_id: openProject },
  })

  const projectList = buildingProjects.getBuildingsByProject || []

  const urnList = projectList.map(
    ({ urn, cord_x, cord_y, cord_z, rotation, updated_at }) => ({
      urn,
      cord_x,
      cord_y,
      cord_z,
      rotation,
      updated_at,
    }),
  )

  useEffect(() => {
    forge.resizeCanvas()
  }, [expandDrawOpen])

  const resize = val => {
    // this needs dobounbcing really
    setSize(val)
    forge.resizeCanvas()
  }

  return (
    <div className="viwer__container">
      <SplitPane
        split="vertical"
        minSize={MIN_SIZE}
        maxSize={1000}
        size={finalSize}
        onChange={resize}
      >
        <div className="viewer__child">
          <PopMenu />
        </div>
        <div className="viewer__child">
          <ForgeViewer urnList={urnList} activeProject={openProject} />
        </div>
      </SplitPane>
      <Scenario />
      <Dialog
        open={reportNameModalOpen}
        onClose={resetModal}
        maxWidth="sm"
        fullWidth
      >
        <CaptureReportModal />
      </Dialog>
    </div>
  )
}

Viewer.propTypes = {}

const mapStateToProps = ({ menus, report }) => ({ menus, report })

const mapDispatchToProps = d => ({
  resetModal: obj => d(captureReset(obj)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Viewer)
