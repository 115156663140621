import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      ligth: '#74f5b4',
      main: '#3bc184',
      dark: '#009057',
      contrastText: '#fff',
    },
    secondary: {
      ligth: '#74f5b4',
      main: '#3bc184',
      dark: '#009057',
      contrastText: '#fff',
    },
  },
  typography: {
    h1: { fontSize: 20 },
    h2: {},
    h3: {},
    h4: {},
    h5: {},
    h6: { fontSize: 14, fontWeight: 500 },
    subtitle1: {
      fontSize: 12,
    },
    subtitle2: {},
    body1: {
      fontSize: 14,
    },
    body2: { fontSize: 12 },
    button: {},
    caption: {},
    overline: {},
  },
  props: {
    MuiButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
    },
    MuiToolbar: {
      variant: 'dense',
    },
  },
})

export default theme
