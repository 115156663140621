import { pluginList } from '@plugins'
import groupBy from '@helpers/groupBy'
import degreeToRad from '@helpers/degreeToRad'
import meterToFeet from '@helpers/meterToFeet'
import { reduxStore } from '@root/Router'
import { updateMenuState } from '@redux/actions'

class NavisWorks {
  constructor() {
    this.viewer = null
    this.id = 0
    this.rooms = []
    this.models = []
    this.idList = []
    this.snackFunc = () => {}
  }

  runForge = (token, expireTime, cb, urnList) => {
    this.finished()
    this.idList = urnList

    this.updateDateModalLoading('lengthOfAllModels', urnList.length)

    const options = {
      env: 'AutodeskProduction',
      api: 'derivativeV2',
      getAccessToken: onTokenReady => onTokenReady(token, expireTime),
    }

    Autodesk.Viewing.Initializer(options, async () => {
      var htmlElement = document.getElementById('forgeViewer')
      if (htmlElement) {
        const config3d = {
          extensions: pluginList,
          disabledExtensions: {
            measure: true,
            viewcube: true,
            layerManager: true,
            explode: true,
            //section: true,
            hyperlink: true,
            bimwalk: true,
            fusionOrbit: true,
          },
        }

        this.viewer = new Autodesk.Viewing.GuiViewer3D(htmlElement, config3d)

        this.subToolbar = new Autodesk.Viewing.UI.ControlGroup(
          'visualization-toolbar',
        )

        if (!this.subToolbar) {
          this.subToolbar = new Autodesk.Viewing.UI.ControlGroup(
            'visualization-toolbar',
          )
          this.viewer.toolbar.addControl(this.subToolbar)
        }

        const { default: initPlugins } = await import(
          '../forgeExtentions/index.js'
        )
        await initPlugins(this.viewer)

        // this generates ids on model load
        this.viewer.addEventListener(
          Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
          m => this.makeIdList(m, cb),
        )

        this.viewer.start()
        this.viewer.setTheme('light-theme')
        const profileSettings = {
          name: 'vspace',
          description: 'vspace settings.',
          settings: {
            progressiveRendering: false,
            lightPreset: 18,
            envMapBackground: false,
            ambientShadow: false,
            groundShadow: false,
            groundReflection: false,
            edgeRendering: true,
            viewCube: false,
            ghosting: true,
          },
        }

        const profile = new Autodesk.Viewing.Profile(profileSettings)
        this.viewer.setProfile(profile)

        for (const item of urnList) {
          await this.loadModelProm(item)
        }
      }
    })
  }

  globalDocumentLoad = (doc, options, onLoadModelSuccess, onLoadModelError) => {
    const viewable = doc.getRoot().getDefaultGeometry()
    const path = doc.getViewablePath(viewable)

    // Check if model was updated less than a day ago.
    const updatedAt = this.idList.find(x => `urn:${x.urn}` === doc.myPath)
    const oneDay = 60 * 60 * 24 * 1000
    const dayAgo = Date.now() - oneDay
    if (updatedAt > dayAgo) {
      Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS['If-Modified-Since'] =
        'Sat, 29 Oct 1994 19:43:31 GMT'
    }

    this.viewer.loadModel(path, options, onLoadModelSuccess, onLoadModelError)
  }

  loadModelProm = urn => {
    return new Promise(resolve => {
      const onDocumentLoadSuccess = async (doc, item) => {
        const viewable = doc.getRoot().getDefaultGeometry()

        if (viewable) {
          try {
            const loadOptions = {
              globalOffset: {
                x: 0,
                y: 0,
                z: 0,
              },
              placementTransform: new THREE.Matrix4().setPosition({
                x: meterToFeet(item.cord_x) || 0,
                y: meterToFeet(item.cord_y) || 0,
                z: meterToFeet(item.cord_z) || 0,
              }),
              keepCurrentModels: true,
              isAEC: true,
            }

            const onLoadModelSuccess = model => {
              console.log('loaded succesfull')

              let fragIdsArray = []
              const fragCount = model.getFragmentList().fragments.fragId2dbId
                .length
              for (var fragId = 0; fragId < fragCount; ++fragId) {
                fragIdsArray.push(fragId)
              }

              const axis = new THREE.Vector3(0, 0, 1)
              const angle = degreeToRad(item.rotation) || 0
              const center = model.getBoundingBox().center()

              this.rotateFragments(model, fragIdsArray, axis, angle, center)

              this.updateDateModalLoading('currentModelLoading', model.id)
            }

            const onLoadModelError = () => {
              console.log('loaded failed')
            }

            this.globalDocumentLoad(
              doc,
              loadOptions,
              onLoadModelSuccess,
              onLoadModelError,
            )
          } catch (e) {
            console.log(e)
          }
        }
      }

      const geoLoaded = () => {
        resolve('loaded')

        this.viewer.removeEventListener(
          Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
          geoLoaded,
        )
      }

      this.viewer.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        geoLoaded,
      )

      Autodesk.Viewing.Document.load(
        `urn:${urn.urn}`,
        data => onDocumentLoadSuccess(data, urn),
        this.onDocumentLoadFailure,
      )
    })
  }

  onGeometryLoaded = () => {
    this.viewer.impl.sceneUpdated(true)
  }

  updateScenarioApi = () => {
    // this updates the forge extention with correct scenario ID's
    this.viewer.loadedExtensions.RoomProperties.updateScenario()
    this.viewer.loadedExtensions.ToggleColors.updateData()
  }

  updateColors = () => {
    this.viewer.loadedExtensions.ToggleColors.updateData()
  }

  onDocumentLoadFailure = () => {
    console.error('Failed fetching Forge manifest')
  }

  getModels = () => {
    return this.viewer.impl.modelQueue().getModels()
  }

  openListByMetaId = async idList => {
    const rooms = this.rooms
      .flat()
      .filter(item => idList.includes(item.metaDataId))

    const modelDataWithKey = rooms.reduce((acc, item) => {
      const { modelId, dbId } = item

      if (acc[modelId]) {
        acc[modelId].push(dbId)
      } else {
        acc[modelId] = []
        acc[modelId].push(dbId)
      }

      return acc
    }, {})

    const allModels = this.getModels()

    for (const model of allModels) {
      // reset isolation
      model.setAllVisibility(0)
    }

    for (const [key, ids] of Object.entries(modelDataWithKey)) {
      const correctModel = allModels.find(item => item.id === Number(key))
      this.viewer.isolate(ids, correctModel)
    }
    this.viewer.fitToView(null)
  }

  makeIdList = async (m, cb) => {
    this.id++

    // get all fragments
    var frags = Array.from(m.model.getFragmentList().fragments.fragId2dbId)

    // make promise list so we can load all then do something
    const promNodes = frags.map(
      item =>
        new Promise(resolve => {
          m.model.getProperties(item, data => resolve(data))
        }),
    )

    const allNodes = await Promise.all(promNodes)

    const addTypeDef = allNodes.map(item => {
      const data = {
        ...item,
        id: item.dbId,
        modelId: m.model.getModelId(),
      }

      const findCommentRow = item.properties.find(
        item => item.attributeName === 'Comments',
      )

      if (findCommentRow) {
        // add meta data supplied so we can easily match from the db data
        const parseJsonOfLevel = JSON.parse(findCommentRow.displayValue)
        data.metaData = parseJsonOfLevel
        data.metaData.__typename = 'MetaDataNode'
        data.metaDataId = `${parseJsonOfLevel.bl_id}${parseJsonOfLevel.rm_id}`
      } else {
        data.metaData = null
        data.metaDataId = null
      }

      return data
    })

    const rooms = addTypeDef.sort((a, b) => a.id - b.id)

    this.rooms.push(rooms)

    cb(this.rooms)
    this.showAllModels()

    return rooms
  }

  setColorOfAll = async (data, target, colorCodes) => {
    const getCorrectColor = {
      dv_id: colorCodes.filter(item => item.type === 'dv_id') || [],
      dp_id: colorCodes.filter(item => item.type === 'dp_id') || [],
      rm_type: colorCodes.filter(item => item.type === 'rm_type') || [],
      pr_rm_fu: colorCodes.filter(item => item.type === 'pr_rm_fu') || [],
      room: [],
    }

    const allModels = this.getModels()

    const flatRoomList = this.rooms.flat()

    const attachExtraData = data.map(row => {
      const findData =
        flatRoomList.find(subItem => subItem.metaDataId === row.room_uuid) || {}

      return {
        modelId: findData.modelId,
        dbId: findData.dbId,
        ...row,
        ...(row.room_data ? JSON.parse(row.room_data) : {}),
      }
    })

    const groupByModel = groupBy(attachExtraData, 'modelId')

    for (const [modeldId, data] of Object.entries(groupByModel)) {
      const correctModel = allModels.find(item => item.id === Number(modeldId))
      const groupByType = groupBy(data, target)
      const values = Object.values(groupByType)

      for (const value of values) {
        for (const row of value) {
          const { dbId } = row

          const { r, g, b } =
            getCorrectColor[target].find(item => item.name === row[target]) ||
            {}

          const formatColorForNavis = [
            (r !== undefined ? r : 255) / 255,
            (g !== undefined ? g : 255) / 255,
            (b !== undefined ? b : 255) / 255,
          ]
          this.viewer.setThemingColor(
            dbId,
            new THREE.Vector4(...formatColorForNavis, 1),
            correctModel,
            true,
          )
        }
      }
    }
  }

  clearOldColors = () => {
    const allModels = this.getModels()

    for (const model of allModels) {
      this.viewer.clearThemingColors(model)
    }
  }

  rotateFragments(model, fragIdsArray, axis, angle, center) {
    var quaternion = new THREE.Quaternion()

    quaternion.setFromAxisAngle(axis, angle)

    fragIdsArray.forEach((fragId, idx) => {
      var fragProxy = this.viewer.impl.getFragmentProxy(model, fragId)

      fragProxy.getAnimTransform()

      var position = new THREE.Vector3(
        fragProxy.position.x - center.x,
        fragProxy.position.y - center.y,
        fragProxy.position.z - center.z,
      )

      position.applyQuaternion(quaternion)

      position.add(center)

      fragProxy.position = position

      fragProxy.quaternion.multiplyQuaternions(quaternion, fragProxy.quaternion)

      if (idx === 0) {
        var euler = new THREE.Euler()

        euler.setFromQuaternion(fragProxy.quaternion, 0)
      }

      fragProxy.updateAnimTransform()
    })
  }

  showId = async (idList, modelId) => {
    const allModels = this.getModels()

    for (const model of allModels) {
      this.viewer.isolate([], model)
      model.visibilityManager.setAllVisibility(false)

      if (modelId === model.id) {
        this.viewer.isolate(idList, model)
      } else {
        model.setAllVisibility(0)
      }
    }

    this.viewer.fitToView(null)
  }

  showAllModels = () => {
    if (this.viewer) {
      this.viewer.showAll()
      this.viewer.fitToView(null)
      this.viewer.impl.sceneUpdated(true)
    }
  }

  resizeCanvas = () => {
    if (this.viewer) {
      this.viewer.resize()
    }
  }

  finished = () => {
    if (this.viewer) {
      this.viewer.finish()
      this.viewer = null
      this.id = 0
      this.rooms = []
      this.models = []
      this.idList = []
      Autodesk.Viewing.shutdown()
      this.updateDateModalLoading('lengthOfAllModels', 0)
      this.updateDateModalLoading('currentModelLoading', 0)
    }
  }

  saveSnack = func => {
    this.snackFunc = func
  }

  successMessage = () => {
    this.snackFunc('Action successful', {
      variant: 'success',
    })
  }

  saveUpdateRoomInfoTab = refetch => {
    this.refetch = refetch
  }

  updateRoomInfoTab = () => {
    this.refetch()
  }

  updateDateModalLoading = (target, value) => {
    reduxStore.dispatch(updateMenuState({ [target]: value }))
  }
}

const navisWorks = new NavisWorks()

export default navisWorks
