import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import { useMutation } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import { GET_PROJECTS } from '@queries'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import Spacer from '@components/Spacer/Spacer'
import { DELETE_PROJECT_MUTATION } from '@queries/mutations'

import './DeleteProjectModal.scss'

const randomNumber = () => Math.floor(Math.random() * 10) + 1

const DeleteProjectModal = ({
  project,
  enqueueSnackbar,
  client,
  closeModal,
}) => {
  const [answer, setAnswer] = useState({
    firstNumber: randomNumber(),
    secondNumber: randomNumber(),
    userAnswer: 0,
  })
  const { firstNumber, secondNumber, userAnswer } = answer

  const { _id, name } = project
  const [deleteProjectMutation] = useMutation(DELETE_PROJECT_MUTATION)

  const deleteProjectAction = async e => {
    e.preventDefault()

    if (Number(userAnswer) !== firstNumber + secondNumber) {
      return enqueueSnackbar('Invalid question answer', {
        variant: 'error',
      })
    }

    try {
      e.preventDefault()
      const res = await deleteProjectMutation({
        variables: { _id },
      })

      client.writeQuery({
        query: GET_PROJECTS,
        data: {
          getProjects: res.data.deleteProject,
        },
      })

      enqueueSnackbar('Project Deleted', {
        variant: 'success',
      })

      client.writeData({
        data: { subDrawIsOpen: false },
      })
      closeModal()
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <form onSubmit={deleteProjectAction}>
        <DialogTitle>Confirm Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm you wish to delete {name}. This is final and cannot
            be undone.
          </DialogContentText>
          <DialogContentText>
            Solve the following question to delete this project:
          </DialogContentText>

          <TextField
            label={`What is ${firstNumber} + ${secondNumber} ?`}
            value={userAnswer}
            onChange={e => setAnswer({ ...answer, userAnswer: e.target.value })}
            margin="normal"
            type="number"
            required
          />

          <Spacer />

          <Button variant="contained" color="secondary" type="submit">
            Delete Project
          </Button>
        </DialogContent>
      </form>
    </div>
  )
}

DeleteProjectModal.propTypes = {}

export default withSnackbar(withApollo(DeleteProjectModal))
