const groupBy = (data = [], target) =>
  data.reduce((acc, row) => {
    const targetVal = row[target]
    if (acc[targetVal]) {
      acc[targetVal].push(row)
    } else {
      acc[targetVal] = []
      acc[targetVal].push(row)
    }
    return acc
  }, {})

export default groupBy
