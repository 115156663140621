import React from 'react'
import { string } from 'prop-types'
import './NotFound.scss'

const NotFound = () => (
  <div>
    <h1>Not Found</h1>
  </div>
)

NotFound.propTypes = {
  example: string,
}

export default NotFound
