import React from 'react'
import Container from '@material-ui/core/Container'
import ProfileChangeDetails from '@components/ProfileChangeDetails/ProfileChangeDetails'
import PaddingBox from '@components/PaddingBox/PaddingBox'

import './User.scss'

const User = () => (
  <PaddingBox>
    <Container maxWidth="md">
      <h1>Profile</h1>
      <ProfileChangeDetails />
    </Container>
  </PaddingBox>
)

User.propTypes = {}

export default User
