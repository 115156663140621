import React, { useEffect, useState } from 'react'
import { array } from 'prop-types'
import forge from '@forge'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import { connect } from 'react-redux'

import './BuildingAccordion.scss'

const BuildingAccordion = ({ updateChildrenId, nodes }) => {
  const [expanded, setExpanded] = useState([])
  const { buildingNodes } = nodes
  const data = buildingNodes
    .map(item => {
      const buildLevelData = item.reduce(
        (acc, subItem) => {
          if (subItem.metaDataId) {
            const getPhysicalLevel = subItem.metaData.level
            const getActualLevel = subItem.metaData.fl_id

            if (acc.levels.data.levelRoomData[getPhysicalLevel]) {
              acc.levels.data.levelRoomData[getPhysicalLevel].push(subItem)
              acc.levels.data.levelRoomIds[getPhysicalLevel].push(subItem.dbId)
              acc.levels.data.levelCode[getPhysicalLevel] = getActualLevel
            } else {
              acc.levels.data.levelRoomData[getPhysicalLevel] = []
              acc.levels.data.levelRoomData[getPhysicalLevel].push(subItem)
              acc.levels.data.levelRoomIds[getPhysicalLevel] = []
              acc.levels.data.levelRoomIds[getPhysicalLevel].push(subItem.dbId)
              acc.levels.data.levelCode[getPhysicalLevel] = getActualLevel
            }
            acc.levels.roomsIds.push(subItem.dbId)
          }

          return acc
        },
        {
          levels: {
            data: { levelRoomData: {}, levelRoomIds: [], levelCode: {} },
            roomsIds: [],
          },
        },
      )

      const building = {
        modelName: item[0].modelId,
        bl_id: item[0] && item[0].metaData && item[0].metaData.bl_id,
        ...buildLevelData,
      }

      return building
    })
    .sort((a, b) => a.bl_id.localeCompare(b.bl_id))

  const handleChange = (event, nodes) => {
    setExpanded(nodes)
  }

  useEffect(() => {
    setExpanded(updateChildrenId)
    const childTarget = updateChildrenId[2]
    if (childTarget) {
      // wait for child elemnts to be in the dom
      setTimeout(() => {
        const domTarget = document.getElementById(childTarget)
        domTarget &&
          domTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 1000)
    }
  }, [updateChildrenId])

  // the random 0's in the IDs are because the data has it

  return (
    <div
      className="popMenu__cell popMenu_cell--border"
      style={{ maxHeight: '25%' }}
    >
      <div className="popMenu__menu">
        <div className="popMenu__menu--clip">
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            onNodeToggle={handleChange}
          >
            <TreeItem
              nodeId={String(-1)}
              label="All models"
              onClick={() => {
                forge.showAllModels()
              }}
            />
            {data.map(({ modelName, levels, bl_id }) => {
              const sortedKeyList = Object.keys(levels.data.levelRoomData).sort(
                (a, b) => a - b,
              )
              return (
                <TreeItem
                  nodeId={String(bl_id)}
                  label={'Building: ' + bl_id}
                  onClick={() => {
                    forge.showId(levels.roomsIds, modelName)
                  }}
                  key={'model' + modelName}
                >
                  {sortedKeyList.map((key, subIndex) => {
                    const value = levels.data.levelRoomData[key]
                    return (
                      <TreeItem
                        nodeId={`${bl_id}${key}`}
                        label={'Level: ' + levels.data.levelCode[key]}
                        onClick={() => {
                          forge.showId(levels.data.levelRoomIds[key], modelName)
                        }}
                        key={`${bl_id}${key}`}
                      >
                        {value
                          .sort((a, b) =>
                            a.metaData.rm_id.localeCompare(b.metaData.rm_id),
                          )
                          .map(({ metaData, dbId, modelId }) => (
                            <TreeItem
                              nodeId={`${bl_id}${key}${dbId}`}
                              label={'Room: ' + metaData.rm_id}
                              onClick={() => {
                                forge.showId(dbId, modelId)
                              }}
                              key={`${bl_id}${key}${dbId}`}
                              id={`${bl_id}${key}${dbId}`}
                            />
                          ))}
                      </TreeItem>
                    )
                  })}
                </TreeItem>
              )
            })}
          </TreeView>
        </div>
      </div>
    </div>
  )
}

BuildingAccordion.propTypes = {
  data: array,
}

const mapStateToProps = ({ nodes }) => ({ nodes })

export default connect(
  mapStateToProps,
  null,
)(BuildingAccordion)
