import { InMemoryCache } from 'apollo-cache-inmemory'

const store = new InMemoryCache({
  dataIdFromObject: ({ _id, __typename }) =>
    _id && __typename ? _id + __typename : null,
  freezeResults: true,
})

store.writeData({
  data: {},
})

export default store
