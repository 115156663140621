import React, { useState, Fragment, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_ROOMS_BY_SELECTED, GET_COLOR_BY_PROJECT } from '@queries'
import cx from 'classnames'
import forge from '@forge'
import EditColorModal from '@modals/EditColorModal/EditColorModal'
import Dialog from '@material-ui/core/Dialog'
import { connect } from 'react-redux'
import { updateMenuState, updateFiltersState } from '@redux/actions'
import { GET_SCENARIOS } from '@queries'
import RoomInfoTabsChild from '@components/RoomInfoTabsChild/RoomInfoTabsChild'

import './RoomInfoTabs.scss'

// this is a bit crazy, there are 3 data streams for past, current and future,
// all get merged into the default set to make new ones

const RoomInfoTabs = ({
  filters,
  client,
  labels,
  menus,
  updateStore,
  openFilter,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState({
    target: '',
    name: '',
    _id: '',
  })

  const {
    bl_id_filter,
    fl_id_filter,
    dv_id_filter,
    dp_id_filter,
    rm_type_filter,
    pr_rm_fu_filter,
    openFilterTab,
  } = filters

  const {
    activeScenario,
    activeScenarioParent,
    activeScenarioPrev,
    activeScenarioNext,
    openProject,
  } = menus

  const { data: allData = {}, refetch } = useQuery(GET_ROOMS_BY_SELECTED, {
    variables: {
      project_id: openProject,
      child: activeScenario || '',
      activePastScenario: activeScenarioPrev || '',
      activeFutureScenario: activeScenarioNext || '',
      building_filter: bl_id_filter,
      level_filter: fl_id_filter,
      faculty_filter: dv_id_filter,
      department_filter: dp_id_filter,
      type_filter: rm_type_filter,
      function_filter: pr_rm_fu_filter,
    },
    fetchPolicy: 'no-cache',
  })

  const dataForChild = allData.getRoomsBySelected || {
    previous: [],
    current: [],
    next: [],
  }

  const { data: colorData = {} } = useQuery(GET_COLOR_BY_PROJECT, {
    variables: {
      project_id: openProject,
    },
  })

  const { data: scenarioData = {} } = useQuery(GET_SCENARIOS, {
    variables: { project_id: Number(openProject) },
  })

  const senarioList = scenarioData.getScenarioByProject || []
  const activeScenarioList = senarioList.find(
    item => item._id === activeScenarioParent,
  ) || { scenario_dates: [] }
  const sortedByDateList = [...activeScenarioList.scenario_dates].sort(
    (a, b) => a.date - b.date,
  )

  const colorCodes = colorData.getColorByProject || []

  const proccessModal = item => {
    setModalOpen(true)
    setModalData(item)
  }

  const updateClick = val => {
    openFilter({ openFilterTab: val })
    forge.updateColors()
  }

  useEffect(() => {
    updateStore({ activeScenarioNext: '' })
  }, [activeScenario, updateStore])

  useEffect(() => {
    forge.saveUpdateRoomInfoTab(refetch)
  }, [refetch])

  return (
    <Fragment>
      <h1 className="leftNav__title">
        Details
        <span className="leftNav__title--button" onClick={forge.showAllModels}>
          Clear Selection
        </span>
      </h1>
      <div className="roomInfoTabs__header">
        <h3
          onClick={() => updateClick(1)}
          className={cx({
            'roomInfoTabs__header--active': openFilterTab === 1,
          })}
        >
          Faculty
        </h3>
        <h3
          onClick={() => updateClick(2)}
          className={cx({
            'roomInfoTabs__header--active': openFilterTab === 2,
          })}
        >
          Department
        </h3>
        <h3
          onClick={() => updateClick(3)}
          className={cx({
            'roomInfoTabs__header--active': openFilterTab === 3,
          })}
        >
          Type
        </h3>
        <h3
          onClick={() => updateClick(4)}
          className={cx({
            'roomInfoTabs__header--active': openFilterTab === 4,
          })}
        >
          Function
        </h3>
        <h3
          onClick={() => updateClick(5)}
          className={cx({
            'roomInfoTabs__header--active': openFilterTab === 5,
          })}
        >
          Rooms
        </h3>
      </div>
      <div className="popMenu__cell">
        <div className="popMenu__menu">
          <div className="popMenu__menu--clip">
            <div className="">
              {openFilterTab === 1 && (
                <RoomInfoTabsChild
                  data={dataForChild}
                  target="dv_id"
                  proccessModal={proccessModal}
                  labels={labels.dv}
                  senarioList={sortedByDateList}
                  colorCodes={colorCodes}
                />
              )}

              {openFilterTab === 2 && (
                <RoomInfoTabsChild
                  data={dataForChild}
                  target="dp_id"
                  proccessModal={proccessModal}
                  labels={labels.dp}
                  senarioList={sortedByDateList}
                  colorCodes={colorCodes}
                />
              )}

              {openFilterTab === 3 && (
                <RoomInfoTabsChild
                  data={dataForChild}
                  target="rm_type"
                  proccessModal={proccessModal}
                  labels={labels.rmtype}
                  senarioList={sortedByDateList}
                  colorCodes={colorCodes}
                />
              )}

              {openFilterTab === 4 && (
                <RoomInfoTabsChild
                  data={dataForChild}
                  target="pr_rm_fu"
                  proccessModal={proccessModal}
                  labels={labels.rmpfun}
                  senarioList={sortedByDateList}
                  colorCodes={colorCodes}
                />
              )}

              {openFilterTab === 5 && (
                <table className="roomData__table roomData__table--single">
                  <thead>
                    <tr>
                      <th>Room Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataForChild.current.map(
                      ({ room_code, room_uuid, area }) => {
                        return (
                          <tr
                            key={room_uuid}
                            onClick={() => forge.openListByMetaId([room_uuid])}
                          >
                            <td>
                              {room_uuid} - {Number(area).toLocaleString()}m²
                            </td>
                          </tr>
                        )
                      },
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <Dialog
              open={modalOpen}
              onClose={() => {
                setModalOpen(!modalOpen)
              }}
              maxWidth="md"
              fullwidth
            >
              <EditColorModal
                closeModal={() => setModalOpen(false)}
                data={modalData}
                projectCode={menus.openProject}
              />
            </Dialog>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

RoomInfoTabs.propTypes = {}

const mapStateToProps = ({ labels, filters, menus }) => ({
  labels,
  filters,
  menus,
})

const mapDispatchToProps = d => ({
  updateStore: obj => d(updateMenuState(obj)),
  openFilter: obj => d(updateFiltersState(obj)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoomInfoTabs)
